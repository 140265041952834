import React from "react";
import "./GetStarted.css";
import checkmarkIcon from "../../images/checkmark-icon.png";

interface TaskProps {
  text: string;
  completed: boolean;
  onClick: () => void;
}

const GetStartedTask: React.FC<TaskProps> = ({ text, completed, onClick }) => {
  return (
    <div className="task" onClick={onClick}>
      <div className="task-left">
        <div className={`task-checkbox ${completed ? "completed" : ""}`}>
          {completed && (
            <img
              src={checkmarkIcon}
              height={20}
              alt="Completed"
              className="task-checkmark-icon"
            />
          )}
        </div>
        <div className={`task-text ${completed ? "completed" : ""}`}>
          {text}
        </div>
      </div>
      <div className="task-chevron">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 6L15 12L9 18"
            stroke="#9BA1AB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default GetStartedTask;
