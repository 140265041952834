import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Button,
  Typography,
  Alert,
  Divider,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  Chip,
} from "@mui/material";
import {
  Clear as ClearIcon,
  FilterList as FilterListIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
  FileDownload,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VantaIcon from "../Connectors/vanta.svg";
import SearchIcon from "../../images/search-icon.png";
import XFA_API, { Role, Device, Shadow, User } from "../API/XFA_API";
import { getUserSecurityStatus, DeviceRisk, getDeviceRisk } from "./UserUtils";
import SecurityStatusCell from "./SecurityStatusCell";
import DeviceStatusCell from "./DeviceStatusCell";
import UserDevicesSidebar from "./UserDevicesSidebar";
import EmptyState from "../General/EmptyState";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import {
  lastMenuItemStyles,
  menuItemStyles,
  topMenuItemStyles,
} from "../General/Dropdown/DropdownMenuStyles";
import "./UsersOverview.css";
import { InviteUsersDialog } from "./InviteUsersDialog";
import { DottedCircleIcon } from "./DottedCircleIcon";
import mailIcon from "../../images/mail-icon.png";
import MfaResetIcon from "../../images/mfa_reset.svg";
import DropdownMenu from "../General/Dropdown/DropdownMenu";
import NavigationService from "../../utils/NavigationService";

interface UsersOverviewProps {
  role: Role;
}

function mergeArrays<T>(
  array1: T[],
  array2: T[],
  predicate: (item1: T, item2: T) => boolean,
  mergeFunction: (item1: T, item2: T) => T,
): T[] {
  let mergedArray: T[] = [];
  let usedIndicesArray2: Set<number> = new Set();

  // Iterate over the first array
  array1.forEach((item1, index1) => {
    let matched = false;

    // Iterate over the second array
    array2.forEach((item2, index2) => {
      // Check if the current pair meets the predicate condition
      if (predicate(item1, item2)) {
        // Merge them using the merge function and add to the result array if not already used
        if (!usedIndicesArray2.has(index2)) {
          mergedArray.push(mergeFunction(item1, item2));
          usedIndicesArray2.add(index2);
          matched = true;
        }
      }
    });

    // If no match was found for item1, add it as is
    if (!matched) {
      mergedArray.push(item1);
    }
  });

  // Add remaining items from array2 that were not merged
  array2.forEach((item, index) => {
    if (!usedIndicesArray2.has(index)) {
      mergedArray.push(item);
    }
  });

  return mergedArray;
}

const UsersOverview: React.FC<UsersOverviewProps> = ({ role }) => {
  const { t } = useTranslation();
  const [openInviteUsersDialog, setOpenInviteUsersDialog] =
    useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [users, setUsers] = useState<Shadow[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [invitedUsers, setInvitedUsers] = useState<User[] | undefined>(
    undefined,
  );
  const [invitedLoading, setInvitedLoading] = useState<boolean>(true);
  const [resendInProgress, setResendInProgress] = useState<string>("");
  const [mfaInProgress, setMfaInProgress] = useState<boolean>(false);
  const [confirmMfaReset, setConfirmMfaReset] = useState<string | undefined>(
    undefined,
  );
  const [userDeleteInProgress, setUserDeleteInProgress] =
    useState<boolean>(false);
  const [confirmUserDelete, setConfirmUserDelete] = useState<
    Shadow | undefined
  >(undefined);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(
    undefined,
  );
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);

  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrderAsc, setSortOrder] = useState<boolean>(true);
  const [filterStatus, setFilterStatus] = useState<DeviceRisk | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorActionsEl, setAnchorActionsEl] = useState<null | HTMLElement>(
    null,
  );

  const [selectedUser, setSelectedUser] = useState<Shadow | undefined>(
    undefined,
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeDevicesForSelectedUser, setActiveDevicesForSelectedUser] =
    useState<Device[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchedUsers, setSearchedUsers] = useState<Shadow[]>([]);

  // Use useRef to track the initial render
  const isFirstRender = useRef(true);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const refreshInvitedUsers = useCallback(async () => {
    setInvitedLoading(true);
    try {
      const invitedUsers = await XFA_API.getUsers(
        role.organization.organization_id,
      );
      setInvitedLoading(false);
      setInvitedUsers(invitedUsers);
    } catch (error) {
      setInvitedLoading(false);
      setError(t("users.error"));
    }
  }, [t, role.organization.organization_id]);

  const activeDevicesUser = useCallback(
    (email: string) => {
      let devices = users
        ? users.find((user) => user.email.toLowerCase() === email.toLowerCase())
            ?.devices || []
        : [];
      return devices
        .filter(
          (device) =>
            device.email.toLowerCase() === email.toLowerCase() &&
            Date.now() / 1000 - device.timestamp_submitted < 30 * 24 * 60 * 60,
        )
        .sort((a, b) => b.timestamp_submitted - a.timestamp_submitted);
    },
    [users],
  );

  const filterUsersBySearchTerm = useCallback(
    (users: Shadow[]) => {
      let usersToReturn = users.filter((user) => {
        const securityStatus = getUserSecurityStatus(
          activeDevicesUser(user.email),
        );
        const statusLabels = {
          [DeviceRisk.Safe]: t("Users.safe").toLowerCase(),
          [DeviceRisk.Unknown]: t("Users.unknown").toLowerCase(),
          [DeviceRisk.Unsafe]: t("Users.unsafe").toLowerCase(),
          [DeviceRisk.Unsupported]: t("Users.unsupported").toLowerCase(),
        };

        return (
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (user.devices &&
            user.devices.some((device) =>
              device.computer_name?.name
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()),
            )) ||
          statusLabels[securityStatus].includes(searchTerm.toLowerCase())
        );
      });
      if (searchTerm !== "" && usersToReturn.length < 10) {
        fetchMoreUsers();
      }
      return usersToReturn;
    },
    [searchTerm, users, activeDevicesUser],
  );

  const refreshUsers = useCallback(async () => {
    setLoading(true);
    try {
      const result = await XFA_API.getGroupedDevices(
        role.organization.organization_id,
        undefined,
      );
      if (result) {
        setUsers(result.shadows);
        setLastEvaluatedKey(result.lastEvaluatedKey);
      }
    } catch (error) {
      console.log(error);
      setError(t("users.error"));
    } finally {
      setLoading(false);
    }
  }, [t, role.organization.organization_id]);

  const fetchMoreUsers = useCallback(async () => {
    if (isFetchingMore || !lastEvaluatedKey) return;

    setIsFetchingMore(true);
    try {
      const result = await XFA_API.getGroupedDevices(
        role.organization.organization_id,
        lastEvaluatedKey,
      );
      if (result) {
        setUsers((prevUsers) =>
          mergeArrays(
            prevUsers!,
            result.shadows,
            (a, b) => a.email === b.email,
            (a, b) => {
              a.devices = mergeArrays(
                a.devices,
                b.devices,
                (a, b) => a.device_id === b.device_id,
                (a) => a,
              );
              return a;
            },
          ),
        );
        setLastEvaluatedKey(result.lastEvaluatedKey);
      }
    } catch (error) {
      console.log(error);
      setError(t("users.error"));
    } finally {
      setIsFetchingMore(false);
    }
  }, [isFetchingMore, lastEvaluatedKey, role.organization.organization_id, t]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        fetchMoreUsers();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchMoreUsers]);

  const getSortedUsers = useCallback(
    (users: Shadow[]) => {
      if (!sortField) return users;

      return [...users].sort((a, b) => {
        let fieldA: string | DeviceRisk | undefined;
        let fieldB: string | DeviceRisk | undefined;

        if (sortField === "securityStatus") {
          fieldA = getUserSecurityStatus(activeDevicesUser(a.email));
          fieldB = getUserSecurityStatus(activeDevicesUser(b.email));
        } else {
          fieldA = (a[sortField as keyof Shadow] as unknown as string) || "";
          fieldB = (b[sortField as keyof Shadow] as unknown as string) || "";
        }

        if (fieldA < fieldB) return sortOrderAsc ? -1 : 1;
        if (fieldA > fieldB) return sortOrderAsc ? 1 : -1;
        return 0;
      });
    },
    [sortField, sortOrderAsc, activeDevicesUser],
  );

  const getFilterStatusLabel = (status: DeviceRisk): string => {
    switch (status) {
      case DeviceRisk.Safe:
        return t("Users.safe");
      case DeviceRisk.Unsafe:
        return t("Users.unsafe");
      case DeviceRisk.Unknown:
        return t("Users.unknown");
      default:
        return "";
    }
  };

  const filterActiveUser = (user: Shadow) => {
    const activeDevices = activeDevicesUser(user.email);
    return (
      invitedUsers?.find(
        (invitedUser) =>
          invitedUser.email.toLowerCase() === user.email.toLowerCase(),
      ) !== undefined ||
      !(user.devices.length !== 0 && activeDevices.length === 0)
    );
  };

  const getFilteredUsers = useCallback(
    (users: Shadow[]) => {
      return users.filter(filterActiveUser).filter((user) => {
        if (!filterStatus) return true;
        const activeDevices = activeDevicesUser(user.email);
        if (
          filterStatus === DeviceRisk.Unknown ||
          filterStatus === DeviceRisk.Unsupported
        ) {
          return activeDevices.some(
            (device) => getDeviceRisk(device) === filterStatus,
          );
        }
        return filterStatus === getUserSecurityStatus(activeDevices);
      });
    },
    [filterStatus, activeDevicesUser],
  );

  React.useEffect(() => {
    const sortedUsers = getSortedUsers(users || []);
    const filteredUsers = getFilteredUsers(sortedUsers);
    setSearchedUsers(filterUsersBySearchTerm(filteredUsers));
  }, [
    users,
    searchTerm,
    filterStatus,
    sortField,
    sortOrderAsc,
    activeDevicesUser,
  ]);

  const handleSortChange = (field: string) => {
    if (sortField === field) {
      setSortOrder(!sortOrderAsc);
    } else {
      setSortField(field);
      setSortOrder(true);
    }
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorActionsEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorActionsEl(null);
  };

  const handleFilterChange = (status: DeviceRisk | null) => {
    setFilterStatus(status);
    handleFilterClose();
  };

  const openInviteUsers = useCallback(() => {
    setOpenInviteUsersDialog(true);
  }, []);

  const closeInviteUsers = useCallback(
    (refresh: boolean) => {
      setOpenInviteUsersDialog(false);
      if (refresh) {
        refreshUsers();
      }
    },
    [refreshUsers],
  );

  const handleRowClick = (user: Shadow) => {
    setSelectedUser(user);
    setActiveDevicesForSelectedUser(activeDevicesUser(user.email));
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
    setSelectedUser(undefined);
  };

  const exportCsv = useCallback(async () => {
    let exportUsers: Shadow[] | undefined = undefined;
    let result = await XFA_API.getGroupedDevices(
      role.organization.organization_id,
      undefined,
    );
    if (result) {
      exportUsers = result.shadows;
      while (result && result.lastEvaluatedKey) {
        result = await XFA_API.getGroupedDevices(
          role.organization.organization_id,
          result.lastEvaluatedKey,
        );
        if (result) {
          exportUsers = mergeArrays(
            exportUsers,
            result.shadows,
            (a, b) => a.email === b.email,
            (a, b) => {
              a.devices = mergeArrays(
                a.devices,
                b.devices,
                (a, b) => a.device_id === b.device_id,
                (a) => a,
              );
              return a;
            },
          );
        }
      }
    }
    if (!exportUsers) return;
    let str = "";
    let firstLine = true;
    exportUsers.forEach((user) => {
      let devices = exportUsers
        ? exportUsers.find(
            (subUser) =>
              subUser.email.toLowerCase() === user.email.toLowerCase(),
          )?.devices || []
        : [];
      devices = devices
        .filter(
          (device) =>
            device.email.toLowerCase() === user.email.toLowerCase() &&
            Date.now() / 1000 - device.timestamp_submitted < 30 * 24 * 60 * 60,
        )
        .sort((a, b) => b.timestamp_submitted - a.timestamp_submitted);
      devices.forEach((device) => {
        let line = "";
        let line1 = "";

        Object.entries(device).forEach(([index, value]) => {
          if (
            ![
              "os_uptodate",
              "browser_uptodate",
              "connect",
              "merged_device_ids",
              "merged_into_device_id",
              "skip",
              "unsupported",
              "discovered",
              "google_mobile_device_ids",
              "google_cloud_identity_device_ids",
              "microsoft_device_ids",
            ].includes(index)
          ) {
            if (index === "passwordmanager" && value) {
              let found = false;
              Object.entries(value).forEach(([passwordManagerName, value]) => {
                if (value === true) {
                  line += `${index},`;
                  line1 += `${passwordManagerName},`;
                  found = true;
                }
              });
              if (!found) {
                line += `${index},`;
                line1 += `None,`;
              }
            } else {
              // if value is object, put quotes around json and escape quotes inside json
              let new_value: string;
              if (typeof value === "object") {
                new_value = `"${JSON.stringify(value).replace(/"/g, '""')}"`;
              } else {
                if (value) {
                  new_value = JSON.stringify(value);
                } else {
                  new_value = "";
                }
              }
              line += `${index},`;
              line1 += `${new_value},`;
            }
          }
        });

        if (firstLine) {
          line = line.slice(0, -1);
          str += line + "\r\n";
          firstLine = false;
        }

        line1 = line1.slice(0, -1);
        str += line1 + "\r\n";
      });
    });

    window.open("data:text/csv;charset=utf-8," + encodeURIComponent(str));
  }, [role.organization.organization_id, activeDevicesUser]);

  const redirectToVanta = () => {
    window.open(
      "https://app.eu.vanta.com/integrations?state=available&integration-dialog=65798d109bb1c9bb8ec7fe21",
      "_blank",
    );
  };

  // Check if the user navigated to the page with a filterStatus query parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterStatusParam = params.get("filterStatus");

    if (filterStatusParam) {
      setFilterStatus(filterStatusParam as DeviceRisk);
    }
  }, [location.search]);

  // Check if the user navigated to the page with a user query parameter
  useEffect(() => {
    if (!users) {
      return;
    }
    const params = new URLSearchParams(location.search);
    const userParam = params.get("user");
    if (userParam) {
      setSearchTerm(userParam);
      const user = users.find((u) => u.email === userParam);
      if (user) {
        handleRowClick(user);
      }
    }
  }, [location.search, users]);

  useEffect(() => {
    // Only call refreshUsers on the first render
    if (isFirstRender.current) {
      refreshUsers();
      refreshInvitedUsers();
      isFirstRender.current = false;
    }
  }, [refreshUsers, refreshInvitedUsers]);

  const totalDevices =
    users?.reduce(
      (acc, user) => acc + (activeDevicesUser(user.email).length || 0),
      0,
    ) || 0;

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Typography variant="pagetitle" color="primary">
          <div>{t("navigation.devices")}</div>
        </Typography>
        <div className="invite-buttons">
          <Button variant="secondary" onClick={openInviteUsers}>
            {t("Users.invite")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              NavigationService.navigateToApplications();
            }}
          >
            {t("Users.onboard")}
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <div className="filter-bar">
        <div className="left-top-bar">
          <div className="search-bar">
            <InputAdornment position="start">
              <img src={SearchIcon} alt="search" className="search-icon" />
            </InputAdornment>
            <input
              type="text"
              placeholder={t("Users.searchPlaceholder")}
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>

          <Button
            className="filter-menu"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleActionsClick}
          >
            {t("Users.actions")}
          </Button>
          <Menu
            anchorEl={anchorActionsEl}
            open={Boolean(anchorActionsEl)}
            onClose={handleActionsClose}
          >
            <MenuItem sx={topMenuItemStyles} onClick={() => redirectToVanta()}>
              <img
                src={VantaIcon}
                alt="Vanta"
                style={{
                  height: "14px",
                  width: "14px",
                  marginRight: "8px",
                }}
              />
              {t("Users.vanta")}
            </MenuItem>
            <MenuItem sx={lastMenuItemStyles} onClick={exportCsv}>
              <FileDownload
                style={{
                  height: "14px",
                  width: "14px",
                  marginRight: "8px",
                }}
              />
              {t("Users.exportCSV")}
            </MenuItem>
          </Menu>

          <Button
            className="filter-menu"
            startIcon={<FilterListIcon />}
            onClick={handleFilterClick}
          >
            {t("Users.filter")}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
            style={{ paddingBottom: 0 }}
          >
            <MenuItem
              sx={topMenuItemStyles}
              onClick={() => handleFilterChange(DeviceRisk.Safe)}
            >
              <CheckCircleIcon
                style={{
                  color: "#34D399",
                  height: "14px",
                  width: "14px",
                  marginRight: "8px",
                }}
              />
              {t("Users.safe")}
            </MenuItem>
            <MenuItem
              sx={menuItemStyles}
              onClick={() => handleFilterChange(DeviceRisk.Unsafe)}
            >
              <WarningIcon
                style={{
                  color: "#F87171",
                  height: "14px",
                  width: "14px",
                  marginRight: "8px",
                }}
              />
              {t("Users.unsafe")}
            </MenuItem>
            <MenuItem
              sx={lastMenuItemStyles}
              onClick={() => handleFilterChange(DeviceRisk.Unknown)}
            >
              <div style={{ width: 14, marginRight: 8 }}>
                <DottedCircleIcon />
              </div>
              {t("Users.unknown")}
            </MenuItem>
          </Menu>
          {filterStatus && (
            <Chip
              label={getFilterStatusLabel(filterStatus)}
              style={{
                backgroundColor: "#F3F4F6",
                color: "#393E45",
                fontWeight: 600,
                height: "24px",
                fontSize: "14px",
                marginTop: "4px",
              }}
              deleteIcon={<ClearIcon />}
              onDelete={() => handleFilterChange(null)}
            />
          )}
        </div>
        <div>
          <div className="filter-bar-content">
            {t("Users.totalUsers")}
            <div className="filter-bar-content-numbers">
              {users?.filter(filterActiveUser).length ?? 0}
            </div>
            {t("Users.totalDevices")}
            <div className="filter-bar-content-numbers">{totalDevices}</div>
          </div>
          <div className="filter-bar-content">{t("Users.past30days")}</div>
        </div>
      </div>

      <div className="table w-full" data-cy="users">
        {error && (
          <div style={{ marginBottom: 15 }}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        {users && users.length === 0 ? (
          <EmptyState context="users" onClick={openInviteUsers} />
        ) : loading || invitedLoading ? (
          <div style={{ textAlign: "center", paddingTop: 5 }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className="table"
            >
              <TableHead style={{ background: "var(--color-gray-100)" }}>
                <TableRow>
                  <TableCell
                    onClick={() => handleSortChange("email")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("Users.email")}
                    {sortField === "email" ? (
                      sortOrderAsc ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )
                    ) : (
                      <UnfoldMore />
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSortChange("securityStatus")}
                    style={{ cursor: "pointer", width: "175px" }}
                  >
                    <div>
                      {t("Users.securityStatus")}
                      {sortField === "securityStatus" ? (
                        sortOrderAsc ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )
                      ) : (
                        <UnfoldMore />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{t("Users.deviceStatus")}</TableCell>
                  <TableCell style={{ textAlign: "right" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedUsers.map((user: Shadow, index: number) => (
                  <TableRow className="selectableRow" key={user.email}>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => handleRowClick(user)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {user.devices.find(
                        (device) => device.user?.full_name,
                      ) && (
                        <div className="fullname">
                          {
                            user.devices.find(
                              (device) => device.user?.full_name,
                            )?.user?.full_name
                          }
                        </div>
                      )}
                      <div className="email">{user.email}</div>
                    </TableCell>

                    <SecurityStatusCell
                      user={user}
                      activeDevicesUser={activeDevicesUser}
                      onClick={() => handleRowClick(user)}
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                        width: "175px",
                      }}
                    />
                    <DeviceStatusCell
                      user={user}
                      activeDevicesUser={activeDevicesUser}
                      onClick={() => handleRowClick(user)}
                      style={{ cursor: "pointer" }}
                    />
                    <TableCell style={{ textAlign: "right" }}>
                      <DropdownMenu
                        mode="dropdown"
                        actions={[
                          {
                            label: "Users.resendTooltip",
                            icon: (
                              <img
                                src={mailIcon}
                                alt="Resend"
                                className="icon"
                              />
                            ),
                            onClick: () => {
                              setResendInProgress(user.email);
                              XFA_API.inviteUser(
                                role.organization.organization_id,
                                user.email,
                              ).then(() => {
                                setResendInProgress("");
                              });
                            },
                          },
                          {
                            label: "Users.mfaTooltip",
                            icon: (
                              <img
                                src={MfaResetIcon}
                                alt="Reset MFA"
                                className="icon"
                              />
                            ),
                            onClick: () => setConfirmMfaReset(user.email),
                          },
                          {
                            label: "Users.removeUser",
                            icon: (
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon-delete"
                              >
                                <path
                                  d="M15 6.75L13.5037 15.2597C13.3777 15.977 12.7546 16.5 12.0265 16.5H5.97355C5.24534 16.5 4.62232 15.977 4.49622 15.2597L3 6.75"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.75 4.5H11.5312M11.5312 4.5V3C11.5312 2.17157 10.8597 1.5 10.0312 1.5H7.96875C7.14032 1.5 6.46875 2.17157 6.46875 3V4.5M11.5312 4.5H6.46875M2.25 4.5H6.46875"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ),
                            onClick: () => setConfirmUserDelete(user),
                            delete: true, // This will apply the delete style
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {error && (
              <Alert
                onClose={() => {
                  setError("");
                }}
                severity="error"
              >
                {error}
              </Alert>
            )}
          </TableContainer>
        )}
      </div>
      {openInviteUsersDialog && (
        <InviteUsersDialog
          role={role}
          users={users}
          onInvite={XFA_API.inviteUser}
          onClose={closeInviteUsers}
        />
      )}
      {confirmMfaReset && (
        <ConfirmationDialog
          title={t("Users.mfa.confirmTitle") + confirmMfaReset}
          cancelText={t("Users.mfa.cancel")}
          confirmText={t("Users.mfa.confirm")}
          inProgress={mfaInProgress}
          onCancel={() => setConfirmMfaReset(undefined)}
          onConfirm={() => {
            setMfaInProgress(true);
            XFA_API.deleteMfa(
              role.organization.organization_id,
              undefined,
              confirmMfaReset,
            )
              .catch((e) => setError(e.message))
              .finally(() => {
                setMfaInProgress(false);
                setConfirmMfaReset(undefined);
              });
          }}
        />
      )}
      {confirmUserDelete && (
        <ConfirmationDialog
          title={t("Users.delete.confirmTitle") + confirmUserDelete?.email}
          cancelText={t("Users.delete.cancel")}
          confirmText={t("Users.delete.confirm")}
          inProgress={userDeleteInProgress}
          onCancel={() => setConfirmUserDelete(undefined)}
          onConfirm={async () => {
            setUserDeleteInProgress(true);

            await XFA_API.deleteMfa(
              role.organization.organization_id,
              undefined,
              confirmUserDelete.email,
            ).catch((e) => {
              setError(e.message);
            });

            XFA_API.deleteUser(
              role.organization.organization_id,
              confirmUserDelete.devices.map((device) => {
                return device.device_id;
              }),
              confirmUserDelete.email,
            ).then(() => {
              refreshUsers();
              refreshInvitedUsers();
              setUserDeleteInProgress(false);
              setConfirmUserDelete(undefined);
            });
          }}
        />
      )}
      {isFetchingMore && (
        <div style={{ textAlign: "center", paddingTop: 5 }}>
          <CircularProgress size={30} />
        </div>
      )}

      {selectedUser && (
        <UserDevicesSidebar
          open={sidebarOpen}
          onClose={handleSidebarClose}
          user={selectedUser!}
          activeDevices={activeDevicesForSelectedUser}
          organizationId={role.organization.organization_id}
          onDeleteDevice={() => {
            refreshUsers();
            refreshInvitedUsers();
          }}
        />
      )}
    </div>
  );
};

export default UsersOverview;
