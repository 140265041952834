import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginForm from "./LoginForm";
import { IntercomProvider } from "../../intercom/intercomProvider";
import { LanguageSelector } from "../../../theme/components/LanguageSelector";
import logo from "../../../images/XFA_woordmerk_gray900.svg";
import stars from "../../../images/stars.png";
import HeroImage from "../../../images/policy_dashboard.png";
import "../AuthStateApp.css";
import { useTranslation } from "react-i18next";

interface LoginLayoutProps {
  onSignIn: (user: any) => void;
  onSwitchToSignup: () => void;
  cognitoUser: any;
  setCognitoUser: (user: any) => void;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({
  onSignIn,
  onSwitchToSignup,
  cognitoUser,
  setCognitoUser,
}) => {
  const { t } = useTranslation();
  return (
    <div id="loginBackground">
      <div id="sidebar">
        <div id="sidebarContent">
          <div id="form">
            <div style={{ textAlign: "center" }}>
              <img
                alt="logo"
                src={logo}
                style={{ height: 50, marginBottom: 50 }}
              />
            </div>
            <IntercomProvider>
              <Routes>
                <Route
                  path="/"
                  element={
                    <LoginForm
                      onSignIn={onSignIn}
                      onSwitchToSignup={onSwitchToSignup}
                      cognitoUser={cognitoUser}
                      setCognitoUser={setCognitoUser}
                    />
                  }
                />
                <Route
                  path="/signin"
                  element={
                    <LoginForm
                      onSignIn={onSignIn}
                      onSwitchToSignup={onSwitchToSignup}
                      cognitoUser={cognitoUser}
                      setCognitoUser={setCognitoUser}
                    />
                  }
                />
                <Route
                  path="/signin/:username"
                  element={
                    <LoginForm
                      onSignIn={onSignIn}
                      onSwitchToSignup={onSwitchToSignup}
                      cognitoUser={cognitoUser}
                      setCognitoUser={setCognitoUser}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <LoginForm
                      onSignIn={onSignIn}
                      onSwitchToSignup={onSwitchToSignup}
                      cognitoUser={cognitoUser}
                      setCognitoUser={setCognitoUser}
                    />
                  }
                />
              </Routes>
            </IntercomProvider>
          </div>
        </div>
        <div id="sidebarFooter">
          <LanguageSelector
            languageNames={{ en: "English", nl: "Nederlands" }}
          />
        </div>
      </div>
      <div style={{ position: "relative", width: "100%", overflow: "hidden" }}>
        <div className="topPart">
          <div className="quote">{t("login.quote")}</div>
          <div className="quoteContact">
            <div className="quoteText">
              <div className="quotePerson">Anthony Harrison</div>
              <div className="quoteTitle">Operations Director @ Seen</div>
            </div>
            <img className="quoteStars" alt="stars" src={stars} />
          </div>
        </div>
        <div className="bottomPart">
          <img
            src={HeroImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "left top",
            }}
            alt="Hero"
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "20%",
              backgroundImage:
                "linear-gradient(to top, rgb(243, 244, 246, 1), rgb(243, 244, 246, 0))",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
