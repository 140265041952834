// utils.tsx

/**
 * Adjusts the transparency of an RGBA color string.
 *
 * @param color - The RGBA color string.
 * @param transparency - The desired transparency value.
 * @returns The adjusted RGBA color string.
 */
export const adjustColorTransparency = (
  color: string,
  transparency: number,
): string => {
  const rgbaPattern = /rgba\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/;
  const match = color.match(rgbaPattern);

  if (match) {
    const [_, r, g, b] = match;
    return `rgba(${r}, ${g}, ${b}, ${transparency})`;
  }

  return color;
};
