import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Chip, CircularProgress } from "@mui/material";
import "./Home.css";

import { Routes, Route, Link, useLocation, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ListItemText from "@mui/material/ListItemText";
import DevicesIcon from "@mui/icons-material/Devices";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LanguageIcon from "@mui/icons-material/Translate";
import HelpGuideIcon from "../images/helpguide.svg";
import OverviewIcon from "../images/overview_icon.png";
import ApplicationsIcon from "../images/integrations_icon.png";
import PaymentIcon from "../images/payment_icon.png";
import PoliciesIcon from "../images/policies_icon.png";
import LogoutIcon from "../images/logout.svg";
import DiscoveryIcon from "../images/discovery-icon.svg";
import lockIcon from "../images/lock.svg";

import RoleSelector from "./RoleSelector";

import logo from "../images/XFA_woordmerk_gray900.svg";

import DevicesOverview from "./Devices/DevicesOverview";
import Overview from "./Overview/Overview";
import Statistics from "./Statistics/Statistics";
import NewUsersOverview from "./Users/UsersOverview";
import OrganizationsOverview from "./Organizations/OrganizationsOverview";
import SupportOverview from "./Support/SupportOverview";
import StatusOverview from "./Status/StatusOverview";
import XFA_API, { Role, Application } from "./API/XFA_API";
import OrganizationBilling from "./Organizations/Billing/OrganizationBilling";
import Applications from "./Applications/Applications";
import Connector from "./Connectors/Connector";
import TrialBar from "./Organizations/Billing/TrialBar";

import { IntercomProvider, trackEvent } from "./intercom/intercomProvider";

import {
  getDeviceToken,
  deleteDeviceToken,
  initiateCustomFlow,
} from "xfa-connect-sdk-js";
import LanguagePopUp from "./General/LanguagePopUp";
import { Auth } from "aws-amplify";
import { Buffer } from "buffer";
import SettingsOverview from "./Settings/Settings";
import { SignedInUser } from "./Auth/Login/SignedInUser";
import Discovery from "./Discovery/Discovery";
import PoliciesPage from "./Policies/PoliciesPage";

interface HomeProps {
  user: SignedInUser;
  onSignOut: () => void;
}

interface CustomizedState {
  referrer: string;
}

interface PaymentMethodObj {
  Type: string;
}

export const RoleContext = createContext({} as Role | undefined);

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const [roles, setRoles] = React.useState<Role[] | undefined>();
  const [activeRole, setActiveRole] = React.useState<Role | undefined>();
  const [inactiveRoles, setInactiveRoles] = React.useState<
    Role[] | undefined
  >();
  const location = useLocation();

  const [isLanguagePopUpOpen, setLanguagePopUpOpen] = React.useState(false);

  const handleLanguageButtonClick = () => {
    setLanguagePopUpOpen(true);
  };

  const handleLanguagePopUpClose = () => {
    setLanguagePopUpOpen(false);
  };

  const refreshRoles = React.useCallback((activeId?: string) => {
    XFA_API.getRoles()
      .then((roles) => {
        // Ensure roles are not undefined before proceeding
        if (!roles || roles.length === 0) {
          console.error("No roles found");
          setActiveRole(undefined);
          setInactiveRoles([]);
          return;
        }

        console.log("Roles:", roles);

        Auth.currentSession().then((session) => {
          const idToken = session.getIdToken().getJwtToken();
          const claims = JSON.parse(
            Buffer.from(idToken.split(".")[1], "base64").toString(),
          );
          const rolesFromToken: Role[] = JSON.parse(claims["roles"]) as Role[];

          // Map token roles to actual roles
          if (Array.isArray(rolesFromToken)) {
            rolesFromToken.forEach((role: Role) => {
              roles.forEach((r) => {
                if (
                  role.organization.organization_id ===
                  r.organization.organization_id
                ) {
                  r.blocked_access = role.blocked_access || false;
                }
              });
            });
          }

          setRoles(roles);

          if (roles && roles.length > 0) {
            // Check URL for organization ID
            if (
              location.state !== undefined &&
              location.state !== null &&
              (location.state as CustomizedState).referrer !== undefined &&
              (location.state as CustomizedState).referrer !== null &&
              (location.state as CustomizedState).referrer.includes(
                "organization_id=",
              )
            ) {
              const organizationId = (
                location.state as CustomizedState
              ).referrer
                .split("organization_id=")[1]
                .split("&")[0];
              if (organizationId !== undefined && organizationId !== null) {
                localStorage.setItem("activeRole", organizationId);
              }
            }

            // Determine the active role
            console.log("determining active role");
            const activeRoleId = activeId || localStorage.getItem("activeRole");

            console.log("Active role ID:", activeRoleId);

            if (activeRoleId) {
              const matchingRoles = roles.filter(
                (role) => role.organization.organization_id === activeRoleId,
              );

              if (matchingRoles.length > 0) {
                localStorage.setItem(
                  "activeRole",
                  matchingRoles[0].organization.organization_id,
                );
                setActiveRole(matchingRoles[0]);
                setInactiveRoles(
                  roles.filter(
                    (role) =>
                      role.organization.organization_id !== activeRoleId,
                  ),
                );
                return; // Exit early as we've found our active role
              }
            }

            // If no active role, set the first role as active
            localStorage.setItem(
              "activeRole",
              roles[0].organization.organization_id,
            );
            setActiveRole(roles[0]);
            setInactiveRoles(roles.slice(1));
          } else {
            // No roles found
            setActiveRole(undefined);
            setInactiveRoles([]);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    refreshRoles();
  }, [refreshRoles, props.user]);

  // Changing of roles
  const changeRole = (organizationID: string) => {
    if (roles) {
      const matchingRoles = roles.filter(
        (role) => role.organization.organization_id === organizationID,
      );
      if (matchingRoles.length > 0) {
        localStorage.setItem(
          "activeRole",
          matchingRoles[0].organization.organization_id,
        );
        setActiveRole(matchingRoles[0]);
        setInactiveRoles(
          roles.filter(
            (role) => role.organization.organization_id !== organizationID,
          ),
        );
      }
    }

    // Removing XFA token (for demo purposes)
    deleteDeviceToken();
  };

  const isAdmin = () => {
    if (roles) {
      const matchingRoles = roles.find(
        (role) => role.roleType === "System Administrator",
      );
      if (matchingRoles) {
        return true;
      }
    }
    return false;
  };

  const [checkedForDemoDashboard, setCheckedForDemoDashboard] =
    React.useState(false);

  React.useEffect(() => {
    if (activeRole) {
      // get XFA device token
      const deviceToken = getDeviceToken();

      setCheckedForDemoDashboard(false);
      XFA_API.getDashboardDemo(activeRole.organization.organization_id)
        .then((application: Application) => {
          if (application && application.Enabled && !deviceToken) {
            initiateCustomFlow(
              window.location.href,
              props.user.email,
              application.ApplicationID,
              process.env.REACT_APP_TOKEN_ENDPOINT,
            );
          } else {
            setCheckedForDemoDashboard(true);
          }
        })
        .catch((error) => {
          console.error("Error getting demo dashboard", error);
          setCheckedForDemoDashboard(true);
          console.error(error);
        });
    } else if (roles) {
      setCheckedForDemoDashboard(true);
    }
  }, [activeRole, roles]);

  if (!checkedForDemoDashboard) {
    return (
      <div className="spinnerCenter">
        <CircularProgress />
      </div>
    );
  }

  return (
    <RoleContext.Provider value={activeRole}>
      <IntercomProvider user={props.user} role={activeRole}>
        <Box sx={{ zIndex: 5 }}>
          {activeRole &&
            activeRole.roleType !== "Organization Administrator" &&
            activeRole.organization.organization_id !== "" && (
              <TrialBar role={activeRole} />
            )}
          <Box sx={{ display: "flex", minHeight: "100vh" }}>
            <CssBaseline />

            <NavBar
              {...props}
              drawerWidth={"250px"}
              changeRole={changeRole}
              inactiveRoles={inactiveRoles}
              activeRole={activeRole}
              refreshRoles={refreshRoles}
              isAdmin={isAdmin}
              handleLanguageButtonClick={handleLanguageButtonClick}
            />

            <main className="h-full" style={{ padding: 32, flexGrow: 1 }}>
              {activeRole && (
                <Routes>
                  <Route
                    path="/devices-old"
                    element={<DevicesOverview role={activeRole} />}
                  />
                  {(activeRole.roleType === "Organization Administrator" ||
                    activeRole.roleType === "") && (
                    <>
                      <Route
                        path="/"
                        element={<Overview role={activeRole} />}
                      />
                      <Route
                        path="/overview"
                        element={<Overview role={activeRole} />}
                      />
                      <Route
                        path="/devices"
                        element={<NewUsersOverview role={activeRole} />}
                      />
                      <Route
                        path="/discovery/*"
                        element={<Discovery role={activeRole} />}
                      />
                      <Route
                        path="/applications/*"
                        element={<Applications role={activeRole} />}
                      />
                      <Route
                        path="/connect/:connector"
                        element={<Connector role={activeRole} />}
                      />
                      <Route
                        path="/settings"
                        element={<SettingsOverview role={activeRole} />}
                      />
                      <Route
                        path="/policies/*"
                        element={<PoliciesPage role={activeRole} />}
                      />
                      <Route
                        path="/organization-billing"
                        element={
                          <OrganizationBilling
                            role={activeRole}
                            onSaveBillingAddress={(org) => {
                              if (roles !== undefined) {
                                const index = roles?.findIndex(
                                  (role) =>
                                    role.organization.organization_id ===
                                    activeRole?.organization.organization_id,
                                );
                                if (index !== undefined) {
                                  roles[index].organization = org;
                                  setRoles([...roles]);
                                }
                              }
                            }}
                          />
                        }
                      />
                    </>
                  )}
                  {activeRole.roleType === "System Administrator" && (
                    <>
                      <Route
                        path="/"
                        element={<Statistics role={activeRole} />}
                      />
                      <Route
                        path="/organizations"
                        element={<OrganizationsOverview role={activeRole} />}
                      />
                      <Route
                        path="/status"
                        element={<StatusOverview role={activeRole} />}
                      />
                      <Route
                        path="/support"
                        element={<SupportOverview role={activeRole} />}
                      />
                      <Route
                        path="/settings"
                        element={<SettingsOverview role={activeRole} />}
                      />
                    </>
                  )}
                  {roles && activeRole && (
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to="/"
                          state={{
                            referrer:
                              window.location.pathname + window.location.search,
                          }}
                        />
                      }
                    />
                  )}
                </Routes>
              )}
            </main>
            <LanguagePopUp
              open={isLanguagePopUpOpen}
              onClose={handleLanguagePopUpClose}
            />
          </Box>
        </Box>
      </IntercomProvider>
    </RoleContext.Provider>
  );
};

export default Home;

interface NavBarProps {
  user: SignedInUser;
  drawerWidth: string;
  activeRole: Role | undefined;
  inactiveRoles: Role[] | undefined;
  changeRole: (id: string) => void;
  refreshRoles: (id?: string) => void;
  isAdmin: () => boolean;
  onSignOut: () => void;
  handleLanguageButtonClick: () => void;
}

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
  const { t } = useTranslation();

  const [page, setPage] = React.useState<
    | "overview"
    | "devices"
    | "organizations"
    | "status"
    | "support"
    | "organization-billing"
    | "applications"
    | "devices-old"
    | "discovery"
    | "settings"
    | "policies"
    | undefined
  >(undefined);

  // if location changes, set page
  const location = useLocation();
  React.useEffect(() => {
    switch (location.pathname) {
      case "/": {
        setPage("overview");
        break;
      }
      case "/overview": {
        setPage("overview");
        break;
      }
      case "/devices": {
        setPage("devices");
        break;
      }
      case "/organizations": {
        setPage("organizations");
        break;
      }
      case "/settings": {
        setPage("settings");
        break;
      }
      case "/devices-old": {
        setPage("devices-old");
        break;
      }
      case "/status": {
        setPage("status");
        break;
      }
      case "/support": {
        setPage("support");
        break;
      }
      case "/organization-billing": {
        setPage("organization-billing");
        break;
      }
      case "/applications": {
        setPage("applications");
        break;
      }
      case "/discovery": {
        setPage("discovery");
        break;
      }
      case "/policies": {
        setPage("policies");
        break;
      }
      default: {
        setPage(undefined);
        break;
      }
    }
  }, [location]);

  const { i18n } = useTranslation();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${props.drawerWidth}px)`,
          ml: `${props.drawerWidth}px`,
        }}
      />
      <Drawer
        anchor="left"
        className="homeNavigation"
        sx={{
          width: props.drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            background: "var(--color-gray-100)",
            border: "none",
            width: props.drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
      >
        <div style={{ borderBottom: "1px solid var(--color-gray-200)" }}>
          <img alt="logo" src={logo} style={{ height: 32, margin: 24 }} />
        </div>

        <div style={{ height: "100%" }}>
          {props.activeRole && (
            <List>
              {props.activeRole.roleType === "" && (
                <ListItemButton
                  key="overview"
                  selected={page === "overview"}
                  component={Link}
                  to="/overview"
                  onClick={() => trackEvent("Navigated to Overview")}
                >
                  <ListItemIcon>
                    <img className="navigationIcon" src={OverviewIcon} />
                  </ListItemIcon>

                  <ListItemText primary={t("navigation.overview")} />
                </ListItemButton>
              )}

              {props.activeRole.roleType === "System Administrator" && (
                <ListItemButton
                  key="organizations"
                  selected={page === "organizations"}
                  component={Link}
                  to="/organizations"
                  onClick={() => trackEvent("Navigated to Organizations")}
                >
                  <ListItemIcon>
                    <CorporateFareIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("navigation.organizations")} />
                </ListItemButton>
              )}

              <ListItemButton
                key="devices"
                selected={page === "devices"}
                component={Link}
                to="/devices"
                disabled={props.activeRole.blocked_access}
                onClick={() => trackEvent("Navigated to New Devices")}
              >
                <ListItemIcon>
                  <DevicesIcon />
                </ListItemIcon>
                <ListItemText primary={t("navigation.devices")} />
                {props.activeRole.blocked_access && (
                  <ListItemIcon>
                    <img className="lockIcon" src={lockIcon} />
                  </ListItemIcon>
                )}
              </ListItemButton>

              {props.activeRole.roleType === "System Administrator" && (
                <ListItemButton
                  key="status"
                  selected={page === "status"}
                  component={Link}
                  to="/status"
                  onClick={() => trackEvent("Navigated to Status")}
                >
                  <ListItemIcon>
                    <NetworkCheckIcon />
                  </ListItemIcon>

                  <ListItemText primary={t("navigation.status")} />
                </ListItemButton>
              )}

              <ListItemButton
                key="discovery"
                selected={page === "discovery"}
                component={Link}
                to="/discovery"
                disabled={props.activeRole.blocked_access}
                onClick={() => trackEvent("Navigated to Discovery")}
              >
                <ListItemIcon>
                  <img className="navigationIcon" src={DiscoveryIcon} />
                </ListItemIcon>

                <ListItemText primary={t("navigation.discovery")} />
                {props.activeRole.blocked_access && (
                  <ListItemIcon>
                    <img className="lockIcon" src={lockIcon} />
                  </ListItemIcon>
                )}
                <Chip
                  size="small"
                  label="New"
                  color="primary"
                  style={{ marginLeft: 8 }}
                ></Chip>
              </ListItemButton>

              <ListItemButton
                key="applications"
                selected={page === "applications"}
                component={Link}
                to="/applications"
                disabled={props.activeRole.blocked_access}
                onClick={() => trackEvent("Navigated to Integrations")}
              >
                <ListItemIcon>
                  <img className="navigationIcon" src={ApplicationsIcon} />
                </ListItemIcon>

                <ListItemText primary={t("navigation.applications")} />
                {props.activeRole.blocked_access && (
                  <ListItemIcon>
                    <img className="lockIcon" src={lockIcon} />
                  </ListItemIcon>
                )}
              </ListItemButton>

              <ListItemButton
                key="policies"
                selected={page === "policies"}
                component={Link}
                to="/policies"
                disabled={props.activeRole.blocked_access}
                onClick={() => trackEvent("Navigated to policies")}
              >
                <ListItemIcon>
                  <img className="navigationIcon" src={PoliciesIcon} />
                </ListItemIcon>
                <ListItemText primary={t("navigation.policies")} />
                <Chip
                  size="small"
                  label="New"
                  color="primary"
                  style={{ marginLeft: 8 }}
                ></Chip>
              </ListItemButton>

              {props.activeRole.roleType === "System Administrator" && (
                <ListItemButton
                  key="support"
                  selected={page === "support"}
                  component={Link}
                  to="/support"
                  onClick={() => trackEvent("Navigated to Support")}
                >
                  <ListItemIcon>
                    <SupportAgentIcon />
                  </ListItemIcon>

                  <ListItemText primary={t("navigation.support")} />
                </ListItemButton>
              )}

              {props.activeRole.roleType === "" && (
                <ListItemButton
                  key="organization-billing"
                  selected={page === "organization-billing"}
                  component={Link}
                  to="/organization-billing"
                  onClick={() =>
                    trackEvent("Navigated to Organization Billing")
                  }
                >
                  <ListItemIcon>
                    <img className="navigationIcon" src={PaymentIcon} />
                  </ListItemIcon>

                  <ListItemText primary={t("navigation.organizationBilling")} />
                </ListItemButton>
              )}
            </List>
          )}
        </div>
        <div className="footer">
          <List style={{ marginTop: 8 }}>
            <ListItemButton
              key="settings"
              selected={page === "settings"}
              component={Link}
              to="/settings"
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t("navigation.settings")} />
            </ListItemButton>

            <ListItemButton
              key="help"
              component={Link}
              to="https://docs.xfa.tech"
              target="_blank"
            >
              <ListItemIcon>
                <img className="navigationIcon" src={HelpGuideIcon} />
              </ListItemIcon>
              <ListItemText primary={t("navigation.help")} />
            </ListItemButton>
            <ListItemButton
              key="language"
              component={Button}
              onClick={() =>
                //changeLanguage(i18n.language === "en" ? "nl" : "en")
                props.handleLanguageButtonClick()
              }
            >
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${t("navigation.language.navTitle")}: ${i18n.language.toUpperCase()}`}
              />
            </ListItemButton>
          </List>
          <div style={{ height: 8 }}></div>
          <RoleSelector
            activeRole={props.activeRole}
            inactiveRoles={props.inactiveRoles}
            changeRole={props.changeRole}
            refreshRoles={props.refreshRoles}
          />

          <ListItem
            style={{
              border: "1px solid var(--color-gray-200)",
              color: "var(--color-gray-500)",
              fontSize: 12,
              fontWeight: 500,
              height: 64,
            }}
          >
            <span
              style={{
                width: "90%",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {props.user.email}
            </span>

            <ListItemIcon style={{ margin: 0 }}>
              <Button
                style={{ minWidth: "auto", padding: 8 }}
                onClick={props.onSignOut}
                id="logout-button"
              >
                <img src={LogoutIcon} style={{ height: "16px!important" }} />
              </Button>
            </ListItemIcon>
          </ListItem>
        </div>
      </Drawer>
    </>
  );
};
