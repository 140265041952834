import React from "react";
import { useTranslation } from "react-i18next";
import { Chip, Tooltip } from "@mui/material";
import { Device } from "../API/XFA_API";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faTriangleExclamation,
  faCircleExclamation,
  faCircleInfo,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import Chrome from "../../images/chrome.png";
import Firefox from "../../images/firefox.png";
import Edge from "../../images/edge.png";
import Safari from "../../images/safari.png";

import Android from "../../images/android.svg";
import Windows from "../../images/windows.png";
import Apple from "../../images/apple.png";
import Linux from "../../images/linux.png";
import ChromeOS from "../../images/chromeos.png";

import { parseOS } from "./OS";
import DeviceStatusChip from "../Users/DeviceStatusChip";
import { DeviceRisk, DeviceStatus } from "../Users/UserUtils";
import { RiskIcons } from "../Users/RiskIcons";

export const OSCheck = ({ device }: { device: Device }) => {
  const icon = React.useMemo(() => {
    if (!device.os_name) {
      return;
    }

    switch (device.os_name.toLowerCase()) {
      case "android":
        return Android;
      case "windows":
        return Windows;
      case "macos":
      case "ios":
      case "ipados":
        return Apple;
      case "linux":
        return Linux;
      case "chromeos":
      case "chrome os":
        return ChromeOS;
    }
  }, [device]);

  return (
    device.os_name && (
      <Check
        status="info"
        customIcon={icon}
        mainLabel={
          parseOS(
            device.os_name,
            device.os_version,
            device.os_flavour,
            device.android_security_level,
          ) ?? ""
        }
      />
    )
  );
};

export const Browsers = ({ device }: { device: Device }) => {
  const { t } = useTranslation();

  return (
    <>
      {((device.browser_name === "Chrome" && !device.chrome_version) ||
        (device.browser_name === "Edge" && !device.edge_version) ||
        (device.browser_name === "Firefox" && !device.firefox_version) ||
        (device.browser_name === "Safari" && !device.safari_version)) && (
        <Check
          mainLabel={device.browser_name || t("devices.Browser")}
          subLabel={
            device.browser_version === null
              ? t("devices.unknown")
              : device.browser_uptodate === false
                ? t("devices.outOfDate")
                : undefined
          }
          status={device.browser_uptodate === true ? "ok" : "nok"}
          tooltip={
            device.browser_version
              ? [`Version: ${device.browser_version}`]
              : undefined
          }
        />
      )}
      {device.chrome_version && (
        <Check
          mainLabel="Google Chrome"
          subLabel={
            device.chrome_uptodate === false
              ? t("devices.outOfDate")
              : undefined
          }
          status={device.chrome_uptodate === true ? "ok" : "nok"}
          customIcon={Chrome}
          tooltip={[
            device.chrome_version && `Version: ${device.chrome_version}`,
          ]}
        />
      )}
      {device.firefox_version && (
        <Check
          mainLabel="Firefox"
          subLabel={
            device.firefox_uptodate === false
              ? t("devices.outOfDate")
              : undefined
          }
          status={device.firefox_uptodate === true ? "ok" : "nok"}
          customIcon={Firefox}
          tooltip={[
            device.firefox_version && `Version: ${device.firefox_version}`,
          ]}
        />
      )}
      {device.edge_version && (
        <Check
          mainLabel="Edge"
          subLabel={
            device.edge_uptodate === false ? t("devices.outOfDate") : undefined
          }
          status={device.edge_uptodate === true ? "ok" : "nok"}
          customIcon={Edge}
          tooltip={[device.edge_version && `Version: ${device.edge_version}`]}
        />
      )}
      {device.safari_version && (
        <Check
          mainLabel="Safari"
          subLabel={
            device.safari_uptodate === false
              ? t("devices.outOfDate")
              : undefined
          }
          status={device.safari_uptodate === true ? "ok" : "nok"}
          customIcon={Safari}
          tooltip={[
            device.safari_version && `Version: ${device.safari_version}`,
          ]}
        />
      )}
    </>
  );
};

export const PasswordManagerCheck = ({ device }: { device: Device }) => {
  const { t } = useTranslation();

  if (device.unsupported === true) {
    return <></>;
  }

  if (device.discovered === true) {
    return (
      <DeviceStatusChip
        status={DeviceStatus.Unverified}
        risk={DeviceRisk.Unknown}
        osName={t("devices.PasswordManager")}
      />
    );
  }

  const disabled = React.useMemo(() => {
    return device.passwordmanager === null;
  }, [device.passwordmanager]);

  const passwordmanagers = React.useMemo(() => {
    let passwordmanagers = [];
    if (device.passwordmanager?.lastpass) {
      passwordmanagers.push("LastPass");
    }
    if (device.passwordmanager?.onepassword) {
      passwordmanagers.push("1Password");
    }
    if (device.passwordmanager?.dashlane) {
      passwordmanagers.push("Dashlane");
    }
    if (device.passwordmanager?.bitwarden) {
      passwordmanagers.push("Bitwarden");
    }
    if (device.passwordmanager?.keepass) {
      passwordmanagers.push("KeePass");
    }
    if (device.passwordmanager?.nordpass) {
      passwordmanagers.push("NordPass");
    }
    if (device.passwordmanager?.protonpass) {
      passwordmanagers.push("ProtonPass");
    }
    if (device.passwordmanager?.icloud_passwords) {
      passwordmanagers.push("iCloud Passwords");
    }
    if (device.passwordmanager?.dropbox_passwords) {
      passwordmanagers.push("Dropbox Passwords");
    }
    if (device.passwordmanager?.passbolt) {
      passwordmanagers.push("Passbolt");
    }
    if (device.passwordmanager?.enpass) {
      passwordmanagers.push("Enpass");
    }
    if (device.passwordmanager?.avast_passwords) {
      passwordmanagers.push("Avast Passwords");
    }
    if (device.passwordmanager?.avira_passwords) {
      passwordmanagers.push("Avira Passwords");
    }

    return passwordmanagers;
  }, [device.passwordmanager]);

  const subLabel = React.useMemo(() => {
    if (disabled) {
      return t("devices.checkNotEnabled");
    }

    return passwordmanagers.length > 0
      ? passwordmanagers.join(", ")
      : t("devices.missing");
  }, [passwordmanagers, t, disabled]);

  const status = React.useMemo(() => {
    if (disabled) {
      return "info";
    }

    return passwordmanagers.length > 0 ? "ok" : "warn";
  }, [device.passwordmanager, passwordmanagers]);

  const customFaIcon = React.useMemo(() => {
    if (device.passwordmanager === null) {
      return faEyeSlash;
    }
  }, [device.passwordmanager, disabled]);

  return (
    device.os_name &&
    ["Windows", "Linux", "macOS"].includes(device.os_name) && (
      <Check
        mainLabel={t("devices.PasswordManager")}
        subLabel={subLabel}
        status={status}
        customFaIcon={customFaIcon}
        disabled={device.passwordmanager === null}
      />
    )
  );
};

export type CheckProps = {
  status: "ok" | "warn" | "nok" | "info";
  mainLabel: any;
  subLabel?: string;
  tooltip?: (string | undefined)[];
  customIcon?: React.ReactNode;
  customFaIcon?: any;
  disabled?: boolean;
};

export const Check = ({
  status,
  mainLabel,
  subLabel,
  tooltip,
  customIcon,
  customFaIcon,
  disabled,
}: CheckProps) => {
  return (
    <>
      <Tooltip
        title={
          tooltip && (
            <div>
              {tooltip.map((t) => (
                <div key={t}>{t}</div>
              ))}
            </div>
          )
        }
      >
        <Chip
          disabled={disabled}
          icon={CheckIcon({ status, customIcon, customFaIcon })}
          label={
            <div style={{ lineHeight: "normal" }}>
              <div
                style={{
                  color: "#10131A",
                  marginRight: "8px",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {mainLabel}
              </div>
              <div style={{ fontSize: "x-small" }}>{subLabel}</div>
            </div>
          }
          style={{
            backgroundColor: "var(--color-background-dark)",
            color: "var(--color-gray-900)",
          }}
        />
      </Tooltip>
    </>
  );
};

const CheckIcon = ({
  status,
  customIcon,
}: {
  status: "ok" | "warn" | "nok" | "info";
  customIcon?: any;
  customFaIcon?: any;
}) => {
  const icon = React.useMemo(() => {
    switch (status) {
      case "ok":
        return RiskIcons.safe;
      case "warn":
        return RiskIcons.skipped;
      case "nok":
        return RiskIcons.unsafe;
      case "info":
        return RiskIcons.unknown;
    }
  }, [status]);

  const color = React.useMemo(() => {
    switch (status) {
      case "ok":
        return "#34D399";
      case "warn":
        return "var(--color-yellow)";
      case "nok":
        return "var(--color-red)";
      case "info":
        return "var(--color-gray-400)";
    }
  }, [status]);

  return customIcon ? (
    <img
      src={customIcon}
      height="14px"
      style={{
        height: 20,
        padding: 4,
        borderRadius: "50%",
        backgroundColor: color,
      }}
    />
  ) : (
    icon
  );
};
