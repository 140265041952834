import React from "react";
import "./EmptyState.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import emptyStateIconApplications from "../../images/icon_applications_empty_state.png";
import emptyStateIconUsers from "../../images/icon_users_empty_state.png";

interface EmptyStateProps {
  context: "users" | "applications" | "policies";
  onClick: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ context, onClick }) => {
  const { t } = useTranslation();
  const icons = {
    users: emptyStateIconUsers,
    applications: emptyStateIconApplications,
    policies: emptyStateIconApplications,
  };

  const headerText = {
    users: t("applications.noUsersHeader"),
    applications: t("applications.noApplicationsHeader"),
    policies: t("applications.noPoliciesHeader"),
  };
  const description1Text = {
    users: t("applications.noUsersDescription1"),
    applications: t("applications.noApplicationsDescription1"),
    policies: t("applications.noPoliciesDescription1"),
  };
  const description2Text = {
    users: t("applications.noUsersDescription2"),
    applications: t("applications.noApplicationsDescription2"),
    policies: t("applications.noPoliciesDescription2"),
  };

  const icon = icons[context];
  return (
    <div className="noApplications">
      <div className="emptyStateContent">
        <img src={icon} alt="Icon" className="emptyStateIcon" />{" "}
        <h1 className="emptyStateHeader">{headerText[context]}</h1>
        <p className="emptyStateDescription">{description1Text[context]}</p>
        <p className="emptyStateDescription">{description2Text[context]}</p>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onClick}
          style={{ marginLeft: "auto", marginTop: "32px" }}
        >
          {t("applications.new")}
        </Button>
      </div>
    </div>
  );
};

export default EmptyState;
