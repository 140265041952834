// UserUtils.ts

import { Device } from "../API/XFA_API";

export enum UserRisk {
  Safe = "safe",
  Unsafe = "unsafe",
  Unknown = "unknown",
}

export enum DeviceRisk {
  Unsupported = "unsupported", //Unsupported is when the device is not supported by XFA
  Safe = "safe", //Safe is when all security checks of the policy are met (regardless of verified status)
  Unsafe = "unsafe", //Unsafe is when at least one security check of the policy is not met
  Unknown = "unknown", //Unknown is when not all checks required in policy are known about the device
}

export enum DeviceStatus {
  Verified = "verified", //Verified is when the device is verified by XFA
  Unverified = "unverified", //Unverified is when the device information comes from a source other than XFA
}

/**
 * Filters devices that have been submitted within the last specified number of days.
 *
 * @param devices - Array of device objects.
 * @param daysAgo - Number of days to look back from today. Default is 30 days.
 * @returns Filtered array of recent devices.
 */
export function getRecentDevices(devices: Array<Device>, daysAgo: number = 30) {
  const now = new Date();
  const pastDate = new Date(now);
  pastDate.setDate(now.getDate() - daysAgo);

  return devices.filter((device) => {
    const submittedDate = new Date(device.timestamp_submitted * 1000); // Multiply with 1000 because it doesn't include the milliseconds
    return submittedDate >= pastDate;
  });
}

export function getSupported(device: Device): boolean {
  if (["Windows", "macOS", "Android", "iOS"].includes(device.os_name ?? "")) {
    return true;
  }
  return false;
}

//check if there are confirmed (check for verified false) unsafe checks
function hasUnsafeChecks(device: Device): boolean {
  return (
    (isDefined(device.os_version) && device.os_uptodate !== true) ||
    device.diskencryption_active === false ||
    device.authentication_active === false ||
    (isDesktop(device.os_name) && device.antivirus_enabled === false) ||
    (isDefined(device.chrome_version) && device.chrome_uptodate !== true) ||
    (isDefined(device.firefox_version) && device.firefox_uptodate !== true) ||
    (isDefined(device.edge_version) && device.edge_uptodate !== true) ||
    (isDefined(device.safari_version) && device.safari_uptodate !== true)
  );
}

//check if there are any unknown checks
function hasUnkownChecks(device: Device): boolean {
  return (
    !isDefined(device.os_version) ||
    !isDefined(device.os_uptodate) ||
    !isDefined(device.diskencryption_active) ||
    !isDefined(device.authentication_active) ||
    (isDesktop(device.os_name) && !isDefined(device.antivirus_enabled)) ||
    (isDefined(device.chrome_version) && !isDefined(device.chrome_uptodate)) ||
    (isDefined(device.firefox_version) &&
      !isDefined(device.firefox_uptodate)) ||
    (isDefined(device.edge_version) && !isDefined(device.edge_uptodate)) ||
    (isDefined(device.safari_version) && !isDefined(device.safari_uptodate))
  );
}

/**
 * Get device risk
 *
 * @param device - Device objects.
 * @returns DeviceStatus containing the device risk.
 */
export function getDeviceRisk(device: Device): DeviceRisk {
  if (hasUnsafeChecks(device)) {
    return DeviceRisk.Unsafe;
  } else if (hasUnkownChecks(device)) {
    return DeviceRisk.Unknown;
  } else {
    return DeviceRisk.Safe;
  }
}

/**
 * Get device status
 *
 * @param device - Device objects.
 * @returns DeviceStatus containing the device status.
 */

export function getDeviceStatus(device: Device): DeviceStatus {
  if (
    device.skip === true ||
    device.discovered === true ||
    device.unsupported === true
  ) {
    return DeviceStatus.Unverified;
  }
  return DeviceStatus.Verified;
}

export const getUserSecurityStatus = (activeDevices: Device[]): DeviceRisk => {
  let hasUnsafe = false;
  let hasUnknown = false;
  let allSafe = true;

  if (activeDevices.length === 0) {
    return DeviceRisk.Unknown;
  }

  activeDevices.forEach((device) => {
    const status = getDeviceRisk(device);
    if (status === DeviceRisk.Unsafe) {
      hasUnsafe = true;
      allSafe = false;
    } else if (status === DeviceRisk.Unknown) {
      hasUnknown = true;
      allSafe = false;
    }
  });

  if (hasUnsafe) {
    return DeviceRisk.Unsafe;
  } else if (hasUnknown) {
    return DeviceRisk.Unknown;
  } else if (allSafe) {
    return DeviceRisk.Safe;
  } else {
    return DeviceRisk.Unknown;
  }
};

export const getDeviceType = (device: Device): string => {
  const osName = device.os_name ?? "";

  if (!osName) return "Unknown Device";

  const osNameLower = osName.toLowerCase();

  if (osNameLower.includes("ios")) {
    // Devices discovered in Microsoft will be ios regardless of iPhone/iPad
    if (
      device.discovered &&
      device.microsoft_device_ids &&
      !device.google_mobile_device_ids &&
      !device.google_cloud_identity_device_ids
    ) {
      return "iPhone / iPad";
    }

    return "iPhone";
  }
  if (osNameLower.includes("ipad")) {
    return "iPad";
  }
  if (osNameLower.includes("mac")) {
    return "Mac";
  }
  if (osNameLower.includes("windows")) {
    return "Windows PC";
  }
  if (osNameLower.includes("android")) {
    return "Android Device";
  }
  if (osNameLower.includes("linux")) {
    return "Linux PC";
  }

  return osName; // Default to showing the os_name if no match is found
};

export const isDesktop = (osName: string | undefined) => {
  if (!osName) {
    return false;
  }
  return ["Windows", "macOS", "Linux"].includes(osName);
};

export const isMobile = (osName: string | undefined) => {
  if (!osName) {
    return false;
  }
  return ["iOS", "iPadOS", "Android"].includes(osName);
};

export const isDefined = (value: any): boolean => {
  return value !== null && value !== undefined && value !== "";
};
