import { Paper, Typography, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveContainer, BarChart, Bar, XAxis } from "recharts";
import XFA_API, { Count, Role } from "../API/XFA_API";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Link } from "react-router-dom";

interface UsageProps {
  role: Role;
}

interface DataPoint {
  id: number;
  name: string;
  devices: Count;
  totalDevices: number;
}

const Usage: React.FC<UsageProps> = ({ role }) => {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string>("");
  const [countLoading, setCountLoading] = React.useState<boolean>(true);
  const [graphData, setGraphData] = React.useState<DataPoint[]>([]);
  const [hoveredBarIndex, setHoveredBarIndex] = React.useState<number | null>(
    null,
  );
  const [percentageChange, setPercentageChange] = React.useState<number>(0);

  React.useEffect(() => {
    if (graphData.length > 1) {
      const currentTotal = graphData[graphData.length - 1].totalDevices;
      const previousTotal = graphData[graphData.length - 2].totalDevices;
      const change = ((currentTotal - previousTotal) / previousTotal) * 100;
      setPercentageChange(change);
    }
  }, [graphData]);

  const getDeviceCounts = React.useCallback(
    async (organizationID: string, date: string) => {
      setError("");
      try {
        setCountLoading(true);
        return await XFA_API.getUserCount(organizationID, date);
      } catch (err) {
        console.log(err);
        setError(t("overview.usagePanel.RetrieveError"));
      }
      return {
        count: 0,
        connect_count: 0,
      } as Count;
    },
    [t],
  );

  const devicesGraphDate = React.useCallback(
    async (organizationID: string) => {
      setCountLoading(true);
      const numberOfMonthsHistory = 6;
      const currentTime = new Date(Date.now());
      const dataPromises = [...Array(numberOfMonthsHistory).keys()].map(
        async (m) => {
          const history = new Date(
            currentTime.getFullYear(),
            currentTime.getMonth() - m,
            1,
          );
          const month = t("shortmonths." + String(history.getMonth()));
          const devices = await getDeviceCounts(
            organizationID,
            history.getFullYear().toString() +
              ("0" + (history.getMonth() + 1)).slice(-2),
          );
          return {
            id: m,
            name: month,
            devices: devices,
            totalDevices: devices.count + devices.connect_count,
          } as DataPoint;
        },
      );
      const data: DataPoint[] = await Promise.all(dataPromises);
      data.reverse();
      setGraphData(data);
      setCountLoading(false);
    },
    [getDeviceCounts, t],
  );

  React.useEffect(() => {
    devicesGraphDate(role.organization.organization_id);
  }, [devicesGraphDate, role]);

  const CustomBar = (props: any) => {
    const { x, y, width, height, value, index } = props;
    const fill = index === hoveredBarIndex ? "#393E45" : "#E2E4E8";
    const cursor = "pointer";
    const displayValue = value.toString().replace(/^0+,/, "");

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          rx={6}
          ry={6}
          style={{ cursor }}
          onMouseEnter={() => setHoveredBarIndex(index)}
          onMouseLeave={() => setHoveredBarIndex(null)}
        />
        {index === hoveredBarIndex && (
          <text
            x={x + width / 2}
            y={y + 10}
            textAnchor="middle"
            dominantBaseline="hanging"
            fill="#F9FAFB"
            style={{
              fontFamily: "Manrope",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "18px",
              textAlign: "center",
            }}
          >
            {displayValue}
          </text>
        )}
      </g>
    );
  };

  const CustomTick = (props: any) => {
    const { x, y, payload, index } = props;
    const isHovered = index === hoveredBarIndex;
    const backgroundColor = isHovered ? "#E2E4E8" : "transparent";
    const textColor = isHovered ? "#10131A" : "#878787";

    return (
      <g transform={`translate(${x},${y})`}>
        {isHovered && (
          <rect
            x={-30.335}
            y={-4}
            width={60.67}
            height={32}
            rx={16}
            ry={16}
            fill={backgroundColor}
          />
        )}
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill={textColor}
          fontSize="12px"
          fontWeight={isHovered ? 600 : 400}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const PercentageChange = ({ change }: { change: number }) => {
    const isPositive = change >= 0;
    const backgroundColor = isPositive ? "#D1FAE5" : "#FECACA";
    const textColor = isPositive ? "#065F46" : "#991B1B";
    const ArrowIcon = isPositive ? ArrowUpwardIcon : ArrowDownwardIcon;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "4px",
          borderRadius: "200px",
          height: "24px",
          backgroundColor: backgroundColor,
          color: textColor,
        }}
      >
        {!Number.isNaN(change) && (
          <ArrowIcon style={{ color: textColor, height: "16px" }} />
        )}
        <Typography
          variant="body2"
          style={{ fontWeight: 600, paddingRight: 4 }}
        >
          {Number.isNaN(change) ? "-" : change.toFixed(1)}%
        </Typography>
      </div>
    );
  };

  return (
    <Link to="/billing">
      <Paper
        className="statisticContainer statisticWithTitle usage"
        style={{ paddingTop: 20 }}
      >
        <div className="statisticTitle">{t("overview.usage")}</div>
        <div className="statisticContent">
          {countLoading || graphData.length === 0 ? (
            <span className="usageGraphPlaceholder statisticContent">
              <CircularProgress />
            </span>
          ) : error === "" ? (
            <>
              <div className="statisticCombo">
                <span className="bigMetric">
                  <div className="bigMetricNumber">
                    {graphData[graphData.length - 1].totalDevices}
                  </div>
                  <div className="bigMetricLabel">
                    <PercentageChange change={percentageChange} />
                    <Typography variant="body2" className="deviceUsageLabel">
                      {t("overview.devicesUsage")}
                    </Typography>
                  </div>
                </span>
              </div>
              <span className="usageGraph">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={graphData} barGap={8}>
                    <Bar
                      dataKey="totalDevices"
                      stackId="a"
                      shape={<CustomBar />}
                    />
                    <XAxis
                      dataKey="name"
                      axisLine={false}
                      tickLine={false}
                      tick={<CustomTick />}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </span>
            </>
          ) : (
            error
          )}
        </div>
      </Paper>
    </Link>
  );
};
export default Usage;
