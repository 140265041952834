import React from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  CircularProgress,
  Divider,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import "./Overview.css";
import XFA_API, { Device, Role } from "../API/XFA_API";
import OverviewEmptyState from "./OverviewEmptyState";
import GetStarted, { Task } from "./GetStarted";
import { trackEvent } from "../intercom/intercomProvider";
import Usage from "../Statistics/Usage";
import Health from "../Statistics/Health";
import OperatingSystem from "../Statistics/OperatingSystems";
import blurredBackground from "../../images/overlay_blur_trial.png";
import NavigationService from "../../utils/NavigationService";

interface StatisticsProps {
  role: Role;
}

const Statistics: React.FC<StatisticsProps> = (props: StatisticsProps) => {
  const { t } = useTranslation();

  const [isBlocked] = React.useState<boolean>(props.role.blocked_access);
  const [applicationCount, setApplicationCount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(true);
  const [showEmptyState, setShowEmptyState] = React.useState(true);
  const [showGetStartedEmptyState, setShowGetStartedEmptyState] =
    React.useState(true);
  const [tasks, setTasks] = React.useState<Task[]>([
    {
      id: 1,
      text: t("overview.emptyState.step1"),
      completed: false,
      onClick: () => {
        const currentUrl = window.location.href;
        const redirectUrl = `https://web.xfa.tech/token?redirect_url=${encodeURIComponent(currentUrl + "?taskCompleted=true")}&email=example%40yourorganization.com&application_id=NONE`;
        NavigationService.navigateToExternal(redirectUrl);
        trackEvent("Started " + t("overview.emptyState.step1"));
      },
    },
    {
      id: 2,
      text: t("overview.emptyState.step2"),
      completed: false,
      onClick: () => {
        NavigationService.navigateToApplications();
        trackEvent("Started " + t("overview.emptyState.step2"));
      },
    },
    {
      id: 3,
      text: t("overview.emptyState.step3"),
      completed: false,
      onClick: () => {
        NavigationService.navigateToNewApplication();
        trackEvent("Started " + t("overview.emptyState.step3"));
      },
    },
    {
      id: 4,
      text: t("overview.emptyState.step4"),
      completed: false,
      onClick: () => {
        NavigationService.navigateToDevices();
        localStorage.setItem("devices", JSON.stringify(true));
        trackEvent("Started " + t("overview.emptyState.step4"));
      },
    },
    {
      id: 5,
      text: t("overview.emptyState.step5"),
      completed: false,
      onClick: () => {
        NavigationService.navigateToApplications();
        localStorage.setItem("hasTappedApplications", JSON.stringify(true));
        trackEvent("Started " + t("overview.emptyState.step5"));
      },
    },
  ]);

  const refreshIntegrations = React.useCallback(async () => {
    if (isBlocked) return;
    try {
      setLoading(true);
      const applications = await XFA_API.getApplications(
        props.role.organization.organization_id,
      );
      setApplicationCount(applications.length);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setApplicationError(t("users.error"));
    }
  }, [props.role.organization.organization_id, t]);

  const [applicationError, setApplicationError] = React.useState<string>("");
  const [devicesError, setDevicesError] = React.useState<string>("");
  const [devices, setDevices] = React.useState<Device[] | undefined>(undefined);
  const [devicesLoading, setDevicesLoading] = React.useState<boolean>(true);

  const getDevices = React.useCallback(
    (role: Role) => {
      if (isBlocked) return;
      setDevicesLoading(true);
      XFA_API.getDevices(role.organization.organization_id)
        .then((result) => {
          if (result) {
            setDevices(result);
            setDevicesLoading(false);
          } else {
            setDevicesError(t("devices.RetrieveError"));
            setDevicesLoading(false);
          }
        })
        .catch(() => {
          setDevicesError(t("devices.RetrieveError"));
          setDevicesLoading(false);
        });
    },
    [t],
  );

  const hasDevices = devices !== undefined && devices?.length > 0;

  React.useEffect(() => {
    if (isBlocked) return;
    getDevices(props.role);
    refreshIntegrations();
  }, [props.role, getDevices, refreshIntegrations]);

  React.useEffect(() => {
    if (isBlocked) return;
    setLoading(true);
    const savedShowEmptyState = localStorage.getItem("showEmptyState");
    const savedShowGetStartedEmptyState = localStorage.getItem(
      "showGetStartedEmptyState",
    );

    if (savedShowEmptyState !== null) {
      setShowEmptyState(JSON.parse(savedShowEmptyState));
    }

    if (savedShowGetStartedEmptyState !== null) {
      setShowGetStartedEmptyState(JSON.parse(savedShowGetStartedEmptyState));
    }

    var updatedTasks = tasks;
    const savedTasks = localStorage.getItem("tasks");
    if (savedTasks) {
      const parsedTasks = JSON.parse(savedTasks);
      updatedTasks = tasks.map((task, index) => ({
        ...task,
        completed: parsedTasks[index]?.completed || task.completed,
      }));
    }

    const params = new URLSearchParams(window.location.search);
    if (params.get("taskCompleted") === "true" || tasks[0].completed) {
      updatedTasks = updatedTasks.map((task) =>
        task.id === 1 ? { ...task, completed: true } : task,
      );
      if (updatedTasks[0].completed && !tasks[0].completed) {
        trackEvent("Completed " + t("overview.emptyState.step1"));
      }
    }

    const hasEnabledDefault = localStorage.getItem("hasEnabledDefault");
    const hasEnabledDefaultStored = hasEnabledDefault
      ? JSON.parse(hasEnabledDefault)
      : false;

    if (
      (hasEnabledDefaultStored && hasEnabledDefaultStored) ||
      tasks[1].completed
    ) {
      updatedTasks = updatedTasks.map((task) =>
        task.id === 2 ? { ...task, completed: true } : task,
      );
      if (updatedTasks[1].completed && !tasks[1].completed) {
        trackEvent("Completed " + t("overview.emptyState.step2"));
      }
    }

    if (applicationCount > 1 || tasks[1].completed) {
      localStorage.setItem("hasIntegration", JSON.stringify(true));
      updatedTasks = updatedTasks.map((task) =>
        task.id === 3 ? { ...task, completed: true } : task,
      );
      if (updatedTasks[2].completed && !tasks[2].completed) {
        trackEvent("Completed " + t("overview.emptyState.step3"));
      }
    }

    const devicesFromLocalStorage = localStorage.getItem("devices");
    const isDevicesStored = devicesFromLocalStorage
      ? JSON.parse(devicesFromLocalStorage)
      : false;

    if ((isDevicesStored && hasDevices) || tasks[4].completed) {
      updatedTasks = updatedTasks.map((task) =>
        task.id === 4 ? { ...task, completed: true } : task,
      );
      if (updatedTasks[3].completed && !tasks[3].completed) {
        trackEvent("Completed " + t("overview.emptyState.step4"));
      }
    }

    const hasIntegration = localStorage.getItem("hasIntegration");
    const hasIntegrationStored = hasIntegration
      ? JSON.parse(hasIntegration)
      : false;
    const hasTappedApplications = localStorage.getItem("hasTappedApplications");
    const hasTappedApplicationsStored = hasTappedApplications
      ? JSON.parse(hasTappedApplications)
      : false;

    if (
      (hasIntegrationStored && hasTappedApplicationsStored) ||
      tasks[4].completed
    ) {
      updatedTasks = updatedTasks.map((task) =>
        task.id === 5 ? { ...task, completed: true } : task,
      );
      if (updatedTasks[4].completed && !tasks[4].completed) {
        trackEvent("Completed " + t("overview.emptyState.step5"));
      }
    }

    localStorage.setItem(
      "tasks",
      JSON.stringify(updatedTasks.map(({ completed }) => ({ completed }))),
    );
    setTasks(updatedTasks);
    setLoading(false);
  }, [applicationCount, hasDevices]);

  const handleDismiss = () => {
    setShowEmptyState(false);
    localStorage.setItem("showEmptyState", JSON.stringify(false));
  };

  const handleGetStartedDismiss = () => {
    setShowGetStartedEmptyState(false);
    localStorage.setItem("showGetStartedEmptyState", JSON.stringify(false));
  };

  if (isBlocked) {
    return (
      <>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <img src={blurredBackground} alt="blurred overview page" />
        </div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "400px",
              padding: "24px",
              borderRadius: "12px",
              textAlign: "center",
              border: "1px solid #D9DCE0",
            },
          }}
          maxWidth="xs"
          open={true}
          hideBackdrop
          style={{
            height: "fit-content",
            width: "fit-content",
            top: "25%",
            left: "40%",
          }}
        >
          <DialogTitle
            style={{
              fontWeight: 600,
              fontSize: "18px",
              padding: 0,
              lineHeight: "28px",
              color: "#101828",
            }}
          >
            {t("overview.trialEnded.popupTitle")}
          </DialogTitle>
          <DialogContent
            style={{
              fontWeight: 400,
              fontSize: "14px",
              padding: 0,
              lineHeight: "20px",
              marginTop: "7px",
              color: "#475467",
            }}
          >
            {t("overview.trialEnded.popupContent")}
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "32px",
              padding: 0,
              gap: "12px",
            }}
          >
            <Button
              style={{
                display: "flex",
                flexGrow: 1,
                height: "40px",
                fontSize: "14px",
                fontWeight: 600,
                border: "1px solid #E2E4E8",
                borderRadius: "6px",
              }}
              variant="outlined"
              autoFocus
              onClick={() => {
                const salesMessage = t("overview.trialEnded.salesMessage");
                window.Intercom("showNewMessage", salesMessage);
              }}
            >
              {t("overview.trialEnded.contactSales")}
            </Button>
            <Button
              style={{
                display: "flex",
                flexGrow: 1,
                height: "40px",
                fontSize: "14px",
                fontWeight: 600,
                borderRadius: "6px",
                margin: 0,
              }}
              variant="contained"
              onClick={() => {
                NavigationService.navigateToBilling();
              }}
            >
              {t("overview.trialEnded.updateBilling")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  if (loading) {
    return (
      <div>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress size={30} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        {!showEmptyState && !showGetStartedEmptyState && (
          <Button
            variant="contained"
            onClick={() => {
              setShowEmptyState(true);
              localStorage.setItem("showEmptyState", JSON.stringify(true));
              setShowGetStartedEmptyState(true);
              localStorage.setItem(
                "showGetStartedEmptyState",
                JSON.stringify(true),
              );
            }}
            style={{ marginLeft: "auto" }}
          >
            {t("overview.emptyState.getStartedTitle")}
          </Button>
        )}
      </div>
      <Divider style={{ marginTop: 16 }} />
      {showEmptyState && (
        <OverviewEmptyState
          tasks={tasks}
          onClick={() => {}}
          onDismiss={handleDismiss}
          dismissable={hasDevices}
        />
      )}
      <div className="statistics">
        <div className="overview">
          {!showEmptyState && showGetStartedEmptyState && (
            <div className="get-started-overview-container">
              <GetStarted
                tasks={tasks}
                dismissable={true}
                onDismiss={handleGetStartedDismiss}
              />
            </div>
          )}
          {devices && devices?.length > 0 && (
            <>
              <Usage role={props.role} />
              <Health devices={devices} />
              <OperatingSystem devices={devices} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
