import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const menuStyles: SxProps<Theme> = {
  padding: 0,
  "& .MuiMenu-list": {
    padding: 0,
  },
};

export const menuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderLeft: "1px solid #E2E4E8",
  borderRight: "1px solid #E2E4E8",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
};

export const topMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px",
  borderTop: "1px solid #E2E4E8",
  borderLeft: "1px solid #E2E4E8",
  borderRight: "1px solid #E2E4E8",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
};

export const deleteMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderTop: "1px solid #E2E4E8",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  border: "1px solid #E2E4E8",
  color: "var(--color-red-700)",
  "& .MuiListItemText-primary": {
    color: "var(--color-red-700)",
    fontSize: "14px",
  },
};

export const lastMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  border: "1px solid #E2E4E8",
  borderTop: "0px",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
};

export const topAndLastMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  border: "1px solid #E2E4E8",
  borderTop: "0px",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
};
