import React, { useEffect, useState } from "react";
import "./OrganizationTab.css";
import { Button, CircularProgress, Divider } from "@mui/material";
import SettingsSection from "./SettingsSection";
import FileUpload from "../General/FileUpload";
import CheckboxWithDescription from "../General/CheckboxWithDescription";
import XFA_API, { Organization, Role } from "../API/XFA_API";
import { use } from "i18next";

export interface OrganizationTabProps {
  t: (key: string) => string;
  organizationID: string;
}

const OrganizationTab: React.FC<OrganizationTabProps> = ({
  t,
  organizationID,
}) => {
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined,
  );
  const [logo, setLogo] = useState<string | undefined>(organization?.logo_url);
  const [organizationName, setOrganizationName] = useState<string>(
    organization?.name ?? "",
  );
  // const [includeInReports, setIncludeInReports] = useState<boolean>(false);
  // const [includeInEmails, setIncludeInEmails] = useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(e.target.value);
    if (!organization) return;

    const updatedOrganization = { ...organization, name: e.target.value };

    setOrganization(updatedOrganization);
  };

  const handleFileSelect = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (organization) {
        organization.logo_url = reader.result as string;
        setOrganization(organization);
      }
      setLogo(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const updateOrganization = async (orgId: string, org: Organization) => {
    await XFA_API.updateOrganization(orgId, org);
  };

  const handleSave = async () => {
    if (!organization) return;
    setLoading(true);

    updateOrganization(organization.organization_id, organization).then(
      async () => {
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    setLoading(true);
    XFA_API.getOrganizationFull(organizationID).then((organization) => {
      if (!organization) {
        setLoading(false);
        return;
      }
      setOrganization(organization);
      setOrganizationName(organization.name);
      setLogo(organization.logo_url);
      setLoading(false);
    });
  }, []);

  return (
    <div className="container">
      <div className="organization-section">
        <div className="test">
          <div className="organization-title-section">
            <div className="inline-settings-header">
              {t("settings.organization.title")}
            </div>
            <div className="inline-settings-description">
              {t("settings.organization.description")}
            </div>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            className="save-button"
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size="20px"
                style={{ marginRight: "10px", color: "white" }}
              />
            )}
            {t("settings.organization.saveButton")}
          </Button>
        </div>
        <Divider />
        <SettingsSection
          title={t("settings.organization.name")}
          description={t("settings.organization.nameDescription")}
        >
          <input
            type="text"
            placeholder={t("settings.organization.namePlaceholder")}
            value={organizationName ?? ""}
            onChange={handleNameChange}
            className="settings-input"
          />
        </SettingsSection>
        <SettingsSection
          title={t("settings.organization.logo")}
          description={t("settings.organization.logoDescription")}
        >
          <div className="logo-upload-section">
            {(logo || organization?.logo_url) && (
              <img
                src={logo ? logo : organization?.logo_url}
                alt="Company Logo"
                className="uploaded-logo"
              />
            )}
            <FileUpload onFileSelect={handleFileSelect} t={t} />
          </div>
        </SettingsSection>
        {/* hide for now
         <SettingsSection
          title={t("settings.organization.branding")}
          description={t("settings.organization.brandingDescription")}
        >
          <div className="logo-upload-section">
            <div>
              <CheckboxWithDescription
                label={t("settings.organization.reports")}
                description={t("settings.organization.reportsDescription")}
                checked={includeInReports}
                onChange={setIncludeInReports}
              />
              <CheckboxWithDescription
                label={t("settings.organization.emailToUsers")}
                description={t("settings.organization.emailToUsersDescription")}
                checked={includeInEmails}
                onChange={setIncludeInEmails}
              />
            </div>
          </div>
        </SettingsSection> */}
      </div>
    </div>
  );
};

export default OrganizationTab;
