import React, { useState } from "react";
import {
  Typography,
  FormControl,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";

interface CopyIdComponentProps {
  title: string;
  value: string;
  disabled: boolean;
}

const CopyIdComponent: React.FC<CopyIdComponentProps> = ({
  title,
  value,
  disabled,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    if (disabled) {
      return;
    }
    await navigator.clipboard.writeText(value);
    setCopied(true);
  };

  return (
    <div>
      <Typography variant="formlabel">{title}</Typography>
      <FormControl
        style={{ width: "100%", flexDirection: "row", alignItems: "center" }}
      >
        <TextField
          required
          id="name"
          value={value}
          InputProps={{
            readOnly: true,
            sx: {
              backgroundColor: "#F3F4F6",
              borderRadius: "8px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#D9DCE0",
                borderRadius: "8px",
              },
              "&:hover fieldset": {
                borderColor: "#D9DCE0",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#D9DCE0",
              },
            },
          }}
          disabled={disabled}
          variant="outlined"
          fullWidth={true}
        />
        <Tooltip
          title={
            disabled
              ? ""
              : copied
                ? t("applications.createDialog.copied")
                : t("applications.createDialog.clickToCopy")
          }
          arrow
          placement="top"
          onClose={() => setCopied(false)}
        >
          <Button
            style={{
              height: "min-content",
              margin: 5,
              padding: 10,
              minWidth: 0,
            }}
            disabled={disabled}
            onClick={handleCopy}
          >
            <ContentCopyIcon fontSize="small" />
          </Button>
        </Tooltip>
      </FormControl>
      <div style={{ marginBottom: 16 }} />
    </div>
  );
};

export default CopyIdComponent;
