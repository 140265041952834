import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Alert, CircularProgress } from "@mui/material";
import XFA_API, { Role } from "../API/XFA_API";
import { useParams } from "react-router-dom";
import VantaIcon from "./vanta.svg";
import Button from "@mui/material/Button";
import { getCsrfToken } from "./Csrf";
import { getCodeFromVanta, getRedirectUri } from "./Vanta";

interface ConnectorProps {
  role: Role;
}

const Connector: React.FC<ConnectorProps> = (props: ConnectorProps) => {
  const { t } = useTranslation();
  const { connector } = useParams<{ connector: string }>();

  const [loading, setLoading] = React.useState(false);
  const [connected, setConnected] = React.useState(false);
  const [error, setError] = React.useState("");

  const exchangeVantaCodeOnAPI = async (
    code: String,
    organizationId: String,
    redirectUri: String,
  ) => {
    /*
      - RedirectURL: /connect/vanta (must be configured with Vanta)
      - state must be the same as the one passed in the InstallationURL (cookie check)
      - forward querystring to xfa-api (code, source_id/organizationId, redirect_uri)
    */

    setLoading(true);
    try {
      await XFA_API.connectVanta(code, organizationId, redirectUri);
      setLoading(false);
      setConnected(true);
    } catch (error: any) {
      setLoading(false);
      setError(t("vanta.error"));
    }
  };

  React.useEffect(() => {
    // check if we have a code
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("code") && urlParams.has("state")) {
      const code = urlParams.get("code");
      const state = urlParams.get("state");

      // check if csrf token is valid
      if (state !== getCsrfToken()) {
        setError(t("vanta.csrfError"));
      }

      // redirect_uri
      const redirectUri = getRedirectUri();

      // exchange code for token
      exchangeVantaCodeOnAPI(
        code as String,
        props.role.organization.organization_id,
        redirectUri,
      );
    }
  }, []);

  if (connector === "vanta") {
    return (
      <div className="flex h-full justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <img src={VantaIcon} alt="Vanta" className="h-52" />
          {loading ? (
            <CircularProgress className="m-2" />
          ) : error ? (
            <Alert severity="error" className="m-2">
              {error}
            </Alert>
          ) : connected ? (
            <Typography variant="h5" className="p-3">
              Connected to Vanta
            </Typography>
          ) : (
            <>
              <Typography variant="h5" className="p-3">
                Connect to Vanta
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  return getCodeFromVanta(
                    props.role.organization.organization_id,
                  );
                }}
              >
                Connect organization {props.role.organization.name}
              </Button>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-5">
        <Alert severity="error">
          <Typography variant="h5">{t("error")}</Typography>
          <Typography variant="body1">{t("errorConnector")}</Typography>
        </Alert>
      </div>
    );
  }
};

export default Connector;
