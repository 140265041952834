import React from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";

import XFA_API from "./API/XFA_API";

import "./CreateOrganizationDialog.css";
import { trackEvent } from "./intercom/intercomProvider";

interface Props {
  onClose: (created: boolean, organizationId?: string) => void;
}

const CreateOrganizationDialog: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const [error, setError] = React.useState<string | undefined>(undefined);
  const [name, setName] = React.useState<string>("");
  const [nameValid, setNameValid] = React.useState<boolean>(true);
  const [tcChecked, setTcChecked] = React.useState<boolean>(false);

  const [creating, setCreating] = React.useState(false);

  const createOrganization = React.useCallback(async () => {
    if (name && name !== "") {
      setCreating(true);
      try {
        const organization = await XFA_API.createOrganization(name);
        setCreating(false);
        if (organization) {
          trackEvent("Created organization", name);
          props.onClose(true, organization.organization_id);
        } else {
          setError("Couldn't create organization");
        }
      } catch (error: any) {
        setError(error);
      }
    }
  }, [props, name]);

  const handleName = (event: any) => {
    setName(event.target.value);
    if (name !== "") {
      setNameValid(true);
    } else {
      setNameValid(false);
    }
  };

  return (
    <>
      <Dialog open={true} onClose={() => props.onClose(false)} fullWidth={true}>
        {error && (
          <Alert
            onClose={() => {
              setError(undefined);
            }}
            severity="error"
          >
            t{t("CreateOrganizationDialog.error")}
          </Alert>
        )}
        <DialogTitle>{t("CreateOrganizationDialog.title")}</DialogTitle>
        <DialogContent>
          <div>{t("CreateOrganizationDialog.content")}</div>
          <div style={{ marginBottom: 10 }}>
            <FormControl>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                required
                id="name"
                value={name}
                error={!nameValid}
                disabled={creating}
                label={t("CreateOrganizationDialog.name")}
                onChange={handleName}
                margin="normal"
                style={{
                  textAlign: "left",
                  width: 280,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tcChecked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setTcChecked(event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "0.7rem",
                    }}
                  >
                    <div>
                      <span>
                        {t("CreateOrganizationDialog.checkboxLabel1")}
                      </span>
                      <Link
                        href={"https://legal.xfa.tech/TC.pdf"}
                        target={"_blank"}
                      >
                        {t("CreateOrganizationDialog.checkboxLabel2")}
                      </Link>
                      <span>
                        {t("CreateOrganizationDialog.checkboxLabel3")}
                      </span>
                    </div>
                  </Typography>
                }
              />
            </FormControl>
          </div>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <Button
              onClick={createOrganization}
              disabled={creating || !tcChecked}
              variant="contained"
            >
              {creating && (
                <CircularProgress size="20px" style={{ marginRight: "10px" }} />
              )}
              {t("CreateOrganizationDialog.createButton")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateOrganizationDialog;
