import { Paper, Typography, CircularProgress, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Device } from "../API/XFA_API";
import "./OperatingSystems.css";
import { adjustColorTransparency } from "../../utils";
import { Link } from "react-router-dom";
import { getRecentDevices } from "../Users/UserUtils";
import NavigationService from "../../utils/NavigationService";

ChartJS.register(ArcElement, Tooltip, Legend);

interface OperatingSystemProps {
  devices: Device[] | undefined;
}

interface OSData {
  name: string;
  count: number;
  color: string;
}

const OperatingSystem: React.FC<OperatingSystemProps> = (
  props: OperatingSystemProps,
) => {
  const { t } = useTranslation();
  const [graphData, setGraphData] = useState<OSData[]>([]);

  useEffect(() => {
    if (props.devices !== undefined) {
      const osCount: { [key: string]: number } = {};
      const recentDevices = getRecentDevices(props.devices);

      recentDevices.forEach((device) => {
        const osName = device.os_name || "Unknown";
        if (osCount[osName]) {
          osCount[osName] += 1;
        } else {
          osCount[osName] = 1;
        }
      });

      const colors = [
        "rgba(239, 68, 68, 0.3)",
        "rgba(245, 158, 11, 0.3)",
        "rgba(29, 78, 216, 0.3)",
        "rgba(16, 185, 129, 0.3)",
        "rgba(251, 191, 36, 0.3)",
        "rgba(110, 231, 183, 0.3)",
        "rgba(234, 88, 12, 0.3)",
        "rgba(16, 185, 129, 0.3)",
        "rgba(37, 99, 235, 0.3)",
      ];

      const data = Object.keys(osCount).map((os, index) => ({
        name: os,
        count: osCount[os],
        color: colors[index % colors.length], // Cycle through colors
      }));

      setGraphData(data);
    }
  }, [props.devices]);

  return (
    <Link to="/devices">
      <Paper
        className="statisticContainer statisticWithTitle"
        style={{ paddingTop: 20 }}
      >
        <div className="statisticTitle">{t("overview.operatingSystems")}</div>
        <div className="statisticsDescription">
          {t("overview.recentDescription")}
        </div>

        <div className="operatingSystemsStatisticContent">
          {props.devices == undefined ? (
            <span className="usageGraphPlaceholder statisticContent">
              <CircularProgress />
            </span>
          ) : graphData.length === 0 ? (
            <span className="usageGraphPlaceholder statisticContent">
              {t("devices.noRecentDevices")}
            </span>
          ) : (
            <>
              <DonutChart data={graphData} />
            </>
          )}
        </div>
      </Paper>
    </Link>
  );
};
export default OperatingSystem;

interface DonutChartProps {
  data: OSData[];
}

const DonutChart: React.FC<DonutChartProps> = ({ data }) => {
  const { t } = useTranslation();
  const totalDevices = data.reduce((acc, os) => acc + os.count, 0);

  const chartData = {
    labels: data.map((os) => os.name),
    datasets: [
      {
        data: data.map((os) => os.count),
        backgroundColor: data.map((os) => os.color),
        borderWidth: 1,
        borderRadius: 200,
        hoverBackgroundColor: data.map((os) =>
          adjustColorTransparency(os.color, 1),
        ),
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    rotation: 0,
    cutout: "85%",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem: { dataIndex: number }[]) => {
            const index = tooltipItem[0].dataIndex;
            const label = chartData.labels[index];
            const value = chartData.datasets[0].data[index];
            return `${value} ${label}`;
          },
          label: () => {
            return "";
          },
        },
        backgroundColor: "rgba(0, 0, 0, 1)",
        displayColors: false,
        zIndex: 1,
      },
    },
    onClick: (evt: any, element: string | any[]) => {
      NavigationService.navigateToDevices();
    },
  };

  return (
    <Box className="donutChartContainer">
      <div className="osChartContainer">
        <Doughnut data={chartData} options={options} />
      </div>
      <Box className="operatingSystemsPercentageContainer">
        <div className="operatingSystemsDescription">
          {t("overview.operatingSystemsPercentageDescription")}
        </div>
        <div className="operatingSystemsPercentage">{totalDevices}</div>
      </Box>
      <Box className="operatingSystemsLegendContainer">
        <Box className="operatingSystemsLegend">
          {data.map((os, index) => (
            <Box className="operatingSystemsLegendItem" key={`legend-${index}`}>
              <Box
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "3px",
                  backgroundColor: adjustColorTransparency(os.color, 1),
                }}
              />
              <Typography variant="body2">
                {os.name} (
                {totalDevices > 0
                  ? ((os.count / totalDevices) * 100).toFixed(2)
                  : 0}
                %)
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
