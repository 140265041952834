import NavigationService from "../../utils/NavigationService";
import { setCsrfToken } from "./Csrf";

export const getCodeFromVanta = (organizationId: string) => {
  /*
		- User should be asked which organization to connect (or create a new one, or login to another account)
		- After decision User should be redirected to https://app.vanta.com/oauth/authorize with following in querystring:
				- client_id: (of our integration).
				- scope: connectors.self:read-resource, connectors.self:write-resource -> We need to write to the Vanta API on behalf of the user.
				- state: random string (to be saved and compared later) -> CSRF protection. This should be stored in a cookie on the xfa.tech domain.
				- redirect_uri: See RedirectURL below, back to dashboard.
				- source_id: Unique identifier for tenant (e.g. Organization ID)
				- response_type: This should always be set to "code".
    */

  // generate csrf token
  const csrfToken = setCsrfToken();

  // parse params
  const vantaClientId = process.env.REACT_APP_VANTA_CLIENT_ID;
  if (!vantaClientId) {
    throw new Error("REACT_APP_VANTA_CLIENT_ID is not set");
  }
  const redirectUri = getRedirectUri();

  // redirect to vanta installation
  const vantaUrl = new URL("https://app.vanta.com/oauth/authorize");
  vantaUrl.searchParams.append("client_id", vantaClientId);
  vantaUrl.searchParams.append(
    "scope",
    "connectors.self:read-resource connectors.self:write-resource",
  );
  vantaUrl.searchParams.append("state", csrfToken);
  vantaUrl.searchParams.append("redirect_uri", redirectUri.toString());
  vantaUrl.searchParams.append("source_id", organizationId);
  vantaUrl.searchParams.append("response_type", "code");
  NavigationService.navigateToExternal(vantaUrl.toString());
};

export const getRedirectUri = () => {
  const redirectUri = new URL(window.location.href);
  redirectUri.search = "";
  redirectUri.pathname = "/connect/vanta";
  return redirectUri.toString();
};
