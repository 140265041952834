import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
  Link,
} from "@mui/material";
import { Auth } from "aws-amplify";
import "../AuthStateApp.css";
import { FederatedLogins } from "../Login/Federated";

interface SignUpProps {
  onSignUp: () => void;
  email: string | undefined;
  name: string | undefined;
  onNameChange: (name: string) => void;
  onFirstNameChange: (firstName: string) => void;
  onEmailChange: (username: string) => void;
  onPasswordChange: (password: string) => void;
  fixedEmail: string | undefined;
  onSwitchToLogin: () => void;
}

const SignUp = (props: SignUpProps) => {
  const [name, setName] = React.useState<string | undefined>("");
  const [firstName, setFirstName] = React.useState<string | undefined>("");
  const [email, setEmail] = React.useState<string | undefined>(
    props.fixedEmail,
  );
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const signUp = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if ((email || props.fixedEmail) && name && password && firstName) {
      setLoading(true);
      const username = email ?? props.fixedEmail!;

      try {
        const user = await Auth.signUp({
          username: username,
          password,
          attributes: {
            email: email ?? props.fixedEmail!,
            family_name: name,
            given_name: firstName,
          },
        });
        localStorage.setItem("signUp", username);
        props.onSignUp();
      } catch (err: any) {
        console.log(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div id="">
      <form onSubmit={signUp}>
        <Typography variant="forminstructions">
          {t("login.signup.subtitle")}
        </Typography>
        <div id="fields">
          <div
            className="field"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            <div id="username">
              <div style={{ marginBottom: "4px", marginTop: "16px" }}>
                <Typography variant="formlabel">
                  {t("login.firstName")}
                </Typography>
              </div>
              <TextField
                id="firstname-input"
                variant="outlined"
                placeholder={t("login.firstNamePlaceholder")}
                fullWidth
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                  props.onFirstNameChange(event.target.value);
                }}
                autoComplete="firstname"
                autoFocus
                disabled={loading}
                required
              />
            </div>
            <div id="username">
              <div style={{ marginBottom: "4px", marginTop: "16px" }}>
                <Typography variant="formlabel">{t("login.name")}</Typography>
              </div>
              <TextField
                id="lastname-input"
                variant="outlined"
                placeholder={t("login.namePlaceholder")}
                fullWidth
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                  props.onNameChange(event.target.value);
                }}
                autoComplete="family-name"
                disabled={loading}
                required
              />
            </div>
          </div>
          <div id="username">
            <div style={{ marginBottom: "4px", marginTop: "16px" }}>
              <Typography variant="formlabel">{t("login.username")}</Typography>
            </div>
            <TextField
              id="username-input"
              variant="outlined"
              placeholder={t("login.usernamePlaceholder")}
              fullWidth
              value={props.fixedEmail !== undefined ? props.fixedEmail : email}
              onChange={(event) => {
                setEmail(event.target.value);
                props.onEmailChange(event.target.value);
              }}
              autoComplete="username"
              disabled={loading || props.fixedEmail !== undefined}
              required
            />
          </div>
          <div id="password">
            <div style={{ marginBottom: "4px", marginTop: "16px" }}>
              <Typography variant="formlabel">{t("login.password")}</Typography>
            </div>
            <Tooltip
              title={t("login.signup.passwordPolicy") || ""}
              arrow
              open={password.length < 12 && password.length > 0}
              placement={"right"}
            >
              <TextField
                id="password-input"
                variant="outlined"
                type="password"
                placeholder={t("login.passwordPlaceholder")}
                fullWidth
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  props.onPasswordChange(event.target.value);
                }}
                autoComplete="current-password"
                disabled={loading}
                required
              />
            </Tooltip>
          </div>
        </div>
        {error && (
          <div id={"errorMessage"}>
            <Alert severity="error">{t(error)}</Alert>
          </div>
        )}
        <div
          className="sendButton"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            variant={"contained"}
            type={"submit"}
            color={"primary"}
            disableElevation
            size="large"
            style={{ width: "100%" }}
            id="signup-button"
            disabled={
              password.length < 12 ||
              email === undefined ||
              name === undefined ||
              firstName === undefined ||
              loading
            }
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("login.signup.continue")
            )}
          </Button>

          <div className="generalConditions">
            <span>{t("login.signup.generalConditions1")}</span>
            <Link href={"https://legal.xfa.tech/TC.pdf"} target={"_blank"}>
              {t("login.signup.generalConditions2")}
            </Link>
            <span>{t("login.signup.generalConditions3")}</span>
          </div>
        </div>
        <FederatedLogins signUp={true} disabled={loading} />
      </form>
    </div>
  );
};

export default SignUp;
