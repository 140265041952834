import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Typography,
} from '@mui/material';
import XFA_API, {BillingSettings, Role} from '../../API/XFA_API';

interface TrialBarProps {
  role: Role;
}

const TrialBar: React.FC<TrialBarProps> = (props: TrialBarProps) => {
  const {t} = useTranslation();
  const [settings, setSettings] = React.useState<BillingSettings>();

  const refreshBillingPlan = React.useCallback(async () => {
    const billingSettings = await XFA_API.getBillingSettings(
        props.role.organization.organization_id,
    );
    setSettings(billingSettings);
  }, [props.role.organization.organization_id]);

  React.useEffect(() => {
    refreshBillingPlan();
  }, [refreshBillingPlan]);

  return (
    <>
      {settings &&
        settings.TrialEndDate != '' &&
        new Date().setFullYear(
            parseInt(settings.TrialEndDate.substring(0, 4)),
            parseInt(settings.TrialEndDate.substring(4, 6)) - 1,
            parseInt(settings.TrialEndDate.substring(6)),
        ) >= new Date().getTime() && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            justifySelf: 'top',
            padding: 5,
            color: 'white',
            backgroundColor: '#111827',
          }}
        >
          <Typography variant="body2">
            {t('Billing.trialEnds')}
            {settings.TrialEndDate.substring(6)}{' '}
            {t('months.' + settings.TrialEndDate.substring(4, 6))}{' '}
            {settings.TrialEndDate.substring(0, 4)}
          </Typography>
        </div>
      )}
    </>
  );
};

export default TrialBar;
