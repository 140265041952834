import React from "react";
import XFA_API, { Role } from "../../API/XFA_API";
import NavigationService from "../../../utils/NavigationService";
import { getMicrosoftRedirectUri } from "../../../utils/DiscoveryService";

interface ConnectMicrosoftProps {
  role: Role;
  organizationId: string;
}

const ConnectMicrosoft: React.FC<ConnectMicrosoftProps> = (
  props: ConnectMicrosoftProps,
) => {
  React.useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    const applicationId = new URLSearchParams(window.location.search).get(
      "state",
    );
    if (code && applicationId) {
      //clear the code to prevent re-creation
      window.history.replaceState({}, document.title, window.location.pathname);

      XFA_API.connectApplication(
        props.organizationId,
        applicationId,
        code,
        getMicrosoftRedirectUri(false),
      )
        .then(() => {
          NavigationService.navigateToDiscovery(applicationId);
        })
        .catch((error) => {
          NavigationService.navigateToDiscovery(applicationId, error);
        });
    }
  }, []);

  return <div>Connecting Microsoft...</div>;
};

export default ConnectMicrosoft;
