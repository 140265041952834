import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { DeviceRisk, DeviceStatus } from "./UserUtils";
import { useTranslation } from "react-i18next";
import { RiskIcons } from "./RiskIcons";

const DeviceStatusChip = ({
  status,
  risk,
  osName,
  tooltip,
}: {
  status: DeviceStatus;
  risk: DeviceRisk;
  osName: string;
  tooltip?: string;
}) => {
  const { t } = useTranslation();
  const icon = RiskIcons[risk] || RiskIcons.unsupported;
  return (
    <Tooltip
      title={
        risk === DeviceRisk.Unknown
          ? t("devices.StatusUnknownTooltip")
          : tooltip || ""
      }
    >
      <Chip
        icon={icon}
        label={osName}
        style={{
          backgroundColor:
            status !== DeviceStatus.Unverified ? "#E2E4E8" : "white",
          color:
            status !== DeviceStatus.Unverified
              ? "#10131A"
              : "var(--color-gray-400)",
          marginRight: "8px",
          marginTop: "4px",
          fontWeight: 600,
          fontSize: "14px",
          height: "24px",
          cursor: "pointer",
          border:
            status === DeviceStatus.Unverified ? "1px dashed black" : "none",
          borderColor: "var(--color-gray-400)",
        }}
      />
    </Tooltip>
  );
};

export default DeviceStatusChip;
