import XFA_API, {
  ApplicationCreationRequest,
  Role,
} from "../components/API/XFA_API";
import { trackEvent } from "../components/intercom/intercomProvider";
import NavigationService from "./NavigationService";

export const startGoogleDiscovery = async (
  role: Role,
  signUp: boolean = false,
) => {
  trackEvent("Selecting Google Integration");
  const application = await XFA_API.createApplication(
    role.organization.organization_id,
    {
      Name: "Google",
      Type: "Google",
    } as ApplicationCreationRequest,
  )
    .then((respApplication) => {
      return respApplication;
    })
    .catch((error) => {
      console.log(error);
    });
  askForGooglePermissions(application.ApplicationID, signUp);
};

export const startMicrosoftDiscovery = async (
  role: Role,
  signUp: boolean = false,
) => {
  trackEvent("Selecting Microsoft Discovery Integration");
  const application = await XFA_API.createApplication(
    role.organization.organization_id,
    {
      Name: "Microsoft Discovery",
      Type: "Microsoft",
    } as ApplicationCreationRequest,
  )
    .then((respApplication) => {
      return respApplication;
    })
    .catch((error) => {
      console.log(error);
    });
  askForMicrosoftPermissions(application.ApplicationID, signUp);
};

export function getMicrosoftRedirectUri(signUp: boolean) {
  if (signUp) {
    return `${window.location.origin}/signup/connect-microsoft`;
  }
  return `${window.location.origin}/discovery/connect-microsoft`;
}

export function askForMicrosoftPermissions(
  applicationId: string,
  signUp: boolean,
) {
  const devicesScope =
    "https://graph.microsoft.com/Device.Read.All+offline_access+https://graph.microsoft.com/AuditLog.Read.All+GroupMember.Read.All";
  const devicesMicrosoftClientId = "947c784d-d758-4f37-89c3-1dd1921f8b08";
  const targetUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?redirect_uri=${encodeURIComponent(
    getMicrosoftRedirectUri(signUp),
  )}&response_type=code&client_id=${devicesMicrosoftClientId}&scope=${devicesScope}&access_type=offline&state=${applicationId}&prompt=consent`;
  NavigationService.navigateToExternal(targetUrl);
}

export function askForGooglePermissions(
  applicationId: string,
  signUp: boolean,
) {
  const devicesScope =
    "https://www.googleapis.com/auth/admin.directory.device.mobile";
  const groupsScope =
    "https://www.googleapis.com/auth/admin.directory.group.readonly";
  const devicesGoogleClientId =
    "948587634820-0nj454fkcegujomr2383lpuru4e0hs64.apps.googleusercontent.com";
  const targetUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${encodeURIComponent(
    getGoogleRedirectUri(signUp),
  )}&response_type=code&client_id=${devicesGoogleClientId}&scope=email%20${devicesScope}%20${groupsScope}&access_type=offline&state=${applicationId}&prompt=consent`;
  NavigationService.navigateToExternal(targetUrl);
}

export function getGoogleRedirectUri(signUp: boolean) {
  if (signUp) {
    return `${window.location.origin}/signup/connect-google`;
  }
  return `${window.location.origin}/discovery/connect-google`;
}
