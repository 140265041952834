import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { UserRisk, DeviceRisk } from "./UserUtils";
import { useTranslation } from "react-i18next";
import { RiskIcons } from "./RiskIcons";

const StatusChip = ({ status }: { status: UserRisk | DeviceRisk }) => {
  const { t } = useTranslation();

  const statusStyles = {
    safe: {
      backgroundColor: "#D1FAE5",
      color: "#065F46",
      icon: RiskIcons.safe,
      label: t("Users.safe"),
      tooltip: "",
    },
    unsafe: {
      backgroundColor: "#FECACA",
      color: "#991B1B",
      icon: RiskIcons.unsafe,
      label: t("Users.unsafe"),
      tooltip: "",
    },
    unknown: {
      backgroundColor: "#FFF7ED",
      color: "#92400E",
      icon: RiskIcons.unknownColored,
      label: t("Users.unknown"),
      tooltip: "",
    },
    unsupported: {
      backgroundColor: "transparent",
      color: "transparent",
      icon: <></>,
      label: "",
      tooltip: "",
    },
  };

  const style = statusStyles[status];
  return (
    <Tooltip title={style.tooltip} placement="top" arrow>
      <Chip
        icon={style.icon}
        label={style.label}
        style={{
          backgroundColor: style.backgroundColor,
          color: style.color,
          fontWeight: 600,
          height: "24px",
          fontSize: "14px",
          marginTop: "4px",
        }}
      />
    </Tooltip>
  );
};

export default StatusChip;
