import React from "react";
import { useTranslation } from "react-i18next";
import {
  Chip,
  FormControl,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import XFA_API, {
  Application,
  ConnectionError,
  GoogleConnection,
} from "../../../API/XFA_API";
import Button from "@mui/material/Button";
import warningIcon from "../../../../images/warning-icon.png";
import "./GooglePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import CopyIdComponent from "../CopyIdComponent";
import BooleanSetting from "../BooleanSetting";
import { GroupsSelector } from "../GroupsSelector";
import { askForGooglePermissions } from "../../../../utils/DiscoveryService";

interface GooglePageProps {
  creating: boolean;
  application: Application;
  setApplication: (application: Application) => void;
  vertical: boolean;
}

const GooglePage: React.FC<GooglePageProps> = (props: GooglePageProps) => {
  const { t } = useTranslation();

  const getGoogleConnection = React.useCallback((application: Application) => {
    if (!application.GoogleConnection) {
      return "disconnected";
    }
    if (application.GoogleConnection.ErrorMobileDevicesAPI) {
      return "error";
    }
    return "connected";
  }, []);

  const [googleConnection, setGoogleConnectionBasic] = React.useState<
    "connected" | "disconnected" | "error"
  >(getGoogleConnection(props.application));

  React.useEffect(() => {
    setGoogleConnectionBasic(getGoogleConnection(props.application));
  }, [props.application, getGoogleConnection]);

  const [googleConnectionTested, setGoogleConnectionTested] =
    React.useState<boolean>(false);
  const [connectionError, setConnectionError] = React.useState<
    ConnectionError | undefined
  >(undefined);

  const [testingConnection, setTestingConnection] =
    React.useState<boolean>(false);

  return (
    <div>
      <div
        className={
          props.vertical ? "verticalSelectionSection" : "selectionSection"
        }
      >
        <div style={{ marginBottom: 16 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="formsectiontitle">
              {t("applications.google.title")}
            </Typography>
            <Chip size="small" label={"Beta"} color="primary" />
          </div>
          <Typography variant="instructions">
            {t("applications.google.content")}
          </Typography>
        </div>
        <div>
          <FormControl fullWidth={true}>
            <Typography variant="setting">
              {t("applications.google.step1label")}
            </Typography>
            <Typography variant="instructions">
              {t("applications.google.step1content")}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <div
                className="connection-status"
                style={{
                  width: "100%",
                }}
              >
                {googleConnection === "connected" && (
                  <span style={{ color: "var(--color-green)" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="application-card-logo"
                    />

                    <span>{t("applications.google.connected")}</span>
                  </span>
                )}
                {googleConnection === "disconnected" && (
                  <span
                    style={{
                      color: "var(--color-yellow)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={warningIcon}
                      alt="warning"
                      className="application-card-logo"
                    />
                    <span>{t("applications.google.disconnected")}</span>
                  </span>
                )}
                {googleConnection === "error" && (
                  <span
                    style={{
                      color: "var(--color-red)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="application-card-logo"
                    />
                    <span>{t("applications.google.error")}</span>
                  </span>
                )}
              </div>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  askForGooglePermissions(
                    props.application.ApplicationID,
                    false,
                  );
                }}
              >
                {googleConnection === "connected"
                  ? t("applications.google.reconnect")
                  : t("applications.google.connect")}
              </Button>
            </div>
            <div style={{ marginBottom: 16 }}></div>
            <Typography variant="setting">
              {t("applications.google.step2label")}
            </Typography>
            <Typography variant="instructions">
              {t("applications.google.step2content")}{" "}
              {t("applications.google.step2instruction1")}{" "}
              <Link
                href="https://admin.google.com/ac/owl/domainwidedelegation"
                target="_blank"
              >
                {t("applications.google.step2link")}
              </Link>
              {t("applications.google.step2instruction2")}
            </Typography>
            <div style={{ marginBottom: 16 }}></div>
            <CopyIdComponent
              title={t("applications.google.clientId")}
              value={"107563464460134163966"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.google.scope")}
              value={"https://www.googleapis.com/auth/cloud-identity.devices"}
              disabled={false}
            />
            <Typography variant="instructions">
              {t("applications.google.step2instruction3")}
            </Typography>
            <div style={{ marginTop: 24 }}></div>
            <BooleanSetting
              label={t("applications.google.excludeDesktop")}
              explanation={t("applications.google.excludeDesktopDescription")}
              value={
                props.application.GoogleConnection?.ExcludeDesktop || false
              }
              onChange={(value) => {
                const application = { ...props.application };
                if (!application.GoogleConnection) {
                  application.GoogleConnection = {} as GoogleConnection;
                }
                application.GoogleConnection.ExcludeDesktop = value;
                props.setApplication(application);
              }}
            />
            <div style={{ marginTop: 16 }}></div>
            <BooleanSetting
              label={t("applications.google.excludeMobile")}
              explanation={t("applications.google.excludeMobileDescription")}
              value={props.application.GoogleConnection?.ExcludeMobile || false}
              onChange={(value) => {
                const application = { ...props.application };
                if (!application.GoogleConnection) {
                  application.GoogleConnection = {} as GoogleConnection;
                }
                application.GoogleConnection.ExcludeMobile = value;
                props.setApplication(application);
              }}
            />
            <div style={{ marginTop: 16 }}></div>
            <BooleanSetting
              label={t("applications.google.onlyUnmanaged")}
              explanation={t("applications.google.onlyUnmanagedDescription")}
              value={props.application.GoogleConnection?.OnlyUnmanaged || false}
              onChange={(value) => {
                const application = { ...props.application };
                if (!application.GoogleConnection) {
                  application.GoogleConnection = {} as GoogleConnection;
                }
                application.GoogleConnection.OnlyUnmanaged = value;
                props.setApplication(application);
              }}
            />
            <div style={{ marginTop: 16 }}></div>
            <GroupsSelector
              application={props.application}
              setApplication={props.setApplication}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 16,
              }}
            >
              <div
                className="connection-status"
                style={{
                  width: "100%",
                }}
              >
                {testingConnection ? (
                  <span
                    style={{
                      color: "var(--color-grey)",
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    <CircularProgress size={14} />

                    <span>{t("applications.google.testing")}</span>
                  </span>
                ) : (
                  googleConnectionTested && (
                    <>
                      {!connectionError?.GoogleCloudIdentityAPIError ? (
                        <span style={{ color: "var(--color-green)" }}>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="application-card-logo"
                          />

                          <span>{t("applications.google.allgood")}</span>
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "var(--color-red)",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            className="application-card-logo"
                          />
                          <span>
                            {connectionError?.GoogleCloudIdentityAPIError}
                          </span>
                        </span>
                      )}
                    </>
                  )
                )}
              </div>
              <Button
                size="small"
                variant="outlined"
                disabled={testingConnection}
                onClick={() => {
                  setTestingConnection(true);
                  XFA_API.triggerDiscovery(
                    props.application.OrganizationID,
                    props.application.ApplicationID,
                  )
                    .then((response: ConnectionError | undefined) => {
                      setTestingConnection(false);
                      setGoogleConnectionTested(true);
                      setConnectionError(response);
                    })
                    .catch((error) => {
                      setTestingConnection(false);
                      setConnectionError(error);
                    });
                }}
              >
                {t("applications.google.test")}
              </Button>
            </div>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default GooglePage;
